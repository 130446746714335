import React from 'react'
import cx from 'classnames'
import Icon from '../../components/icon'
import Avatar from '../../components/avatar'
import ProgressBar from '../../components/progress-bar'
import StarRating from '../../components/star-rating'
import { Heading, Text } from '../../components/typography'
import { format, formatDistance, subDays } from '../../utils/date'
import { SimpleEmpty } from '../../components/empty'
import pluralize from 'pluralize'

export default function Reviews({ account }) {
  const totalRatings = account.reviews.length
  const averageRating = account.rating
  const noTotalRatings = totalRatings <= 0
  return (
    <div className="wrapper-sm">
      {noTotalRatings ? (
        <div className="h-80">
          <SimpleEmpty>
            <Text className="text-center">There are no reviews yet</Text>
          </SimpleEmpty>
        </div>
      ) : (
        <>
          <div className="flex gap-8 md:gap-0 flex-col md:flex-row py-8 border-b border-gray-300">
            <div className="px-8 grid place-content-center gap-2 md:border-r border-gray-300">
              <Heading level={4} size="lg" className="font-medium">
                Average rating
              </Heading>
              <div className="flex items-center gap-2">
                <Text className="text-3xl font-semibold">{averageRating}</Text>
                <StarRating rating={averageRating} />
              </div>
              <Text className="text-gray-500">
                Based on {pluralize('reviews', totalRatings, true)}
              </Text>
            </div>
            <div className="px-8 flex-1">
              <div className="flex flex-col gap-2">
                {Object.keys(account.rating_groups)
                  .reverse()
                  .map((key, i) => (
                    <RateGroup
                      key={key}
                      stars={key}
                      count={account.rating_groups[key].length}
                      totalRatings={totalRatings}
                    />
                  ))}
              </div>
            </div>
          </div>

          {/* Reviews */}
          <div className="py-8">
            <div className="flex flex-col gap-4">
              {account.reviews.map((review, i) => (
                <Review
                  key={review.id}
                  bordered={i !== 0}
                  rating={review.score}
                  name={review.service_name}
                  image={review.avatar}
                  title={review.title}
                >
                  <Text className="whitespace-pre-line mb-4">
                    {review.comment}
                  </Text>
                  <time
                    className="text-sm text-gray-500"
                    dateTime={review.created_at}
                    title={format(new Date(review.created_at), 'MMMM d, yyyy')}
                  >
                    {formatDistance(
                      subDays(new Date(review.created_at), 0),
                      new Date(),
                      {
                        addSuffix: true,
                      }
                    )}
                  </time>
                </Review>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

function RateGroup({ stars, count, totalRatings }) {
  return (
    <div className="flex items-center gap-3">
      <div className="flex gap-1 items-center text-gray-600">
        <span>{stars}</span>
        <Icon name="star" className="w-4 h-4" fill="currentColor" />
      </div>
      <div className="flex-1 md:flex-none md:w-64">
        <ProgressBar
          value={(count / totalRatings) * 100}
          className="bg-brown-600"
        />
      </div>
      <span className="text-gray-600">{count}</span>
    </div>
  )
}

function Review({ bordered = true, title, name, image, rating, children }) {
  const className = cx(
    'flex flex-col md:flex-row gap-4 py-8 border-translucent_dark',
    {
      'border-t': bordered,
    }
  )
  return (
    <div className={className}>
      <div>
        <Avatar src={image} shape="rounded" />
      </div>
      <div className="flex-1">
        <div className="flex flex-col gap-1">
          <Text className="font-semibold text-brown-700">{name}</Text>
          <div>
            <StarRating rating={rating} />
          </div>
          <div>
            <Text className="font-semibold mb-2">{title}</Text>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
