import React from 'react'
import { Heading } from '../components/typography'
import Input from '../components/input'
import Label from '../components/label'
import Button from '../components/button'

export default function Unlock({ authenticityToken, email }) {
  return (
    <div className="h-screen bg-blue-500 flex items-center">
      <div className="mx-auto w-4/5 max-w-md">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="2"
          clipRule="evenodd"
          viewBox="0 0 500 500"
          className="w-24 text-brown-600 mb-8 mx-auto"
          fill="currentColor"
        >
          <path
            fillRule="nonzero"
            d="M157.185 257.694c-44.336 17.275-94.28-4.673-111.555-49.013 44.34-17.265 94.283 4.673 111.555 49.013m-1.126 21.928c-34.93-15.343-75.687.527-91.03 35.458 34.931 15.346 75.684-.528 91.03-35.458m17.1-38.925c-34.927-15.352-50.8-56.105-35.454-91.032 34.927 15.349 50.8 56.102 35.455 91.032"
          />
          <path
            fillRule="nonzero"
            d="M381.48 416a54.088 54.088 0 0 0 7.758-6.267c24.298-23.605 38.635-43.963 39.92-87.133 1.035-35.052-14.063-65.252-31.593-83.301-20.12-20.721-50.25-38.235-88.166-57.968-27.945-14.529-44.837-20.163-63.171-32.675-6.528-4.457-12.606-9.674-17.228-16.092-8.56-11.903-12.455-25.925-13.104-42.302-.067-15.128 4.02-33.761 19.568-50.552 31.633-34.165 89.432-30.02 116.453 15.994 27.02 46.014-4.918 87.063-4.918 87.063s37.486-28.682 16.583-83.785c-20.903-55.1-104.167-78.978-156.235-27.606-32.261 31.835-28.133 74.252-23.168 95.82 3.207 16.505 10.374 29.777 21.088 43.028 14.925 18.48 42.373 38.242 80.705 57.968 31.862 16.368 52.895 36.581 66.012 53.158 13.107 16.586 18.419 31.71 18.072 50.975a72.774 72.774 0 0 1-.252 4.804c-2.4 20.882-12.707 56.418-53.48 77.593-57.221 29.706-130.229-2.246-143.9-74.87-13.669-72.618 51.083-122.027 51.083-122.027s-66.194 30.692-64.752 112.295c.904 51.238 35.845 95.672 84.158 113.135-47.741 9.702-59.487 47.207-59.487 47.207s33.522-44.413 116.447-7.708c114.759 50.801 125.63-50.515 125.63-50.515s-7.11 45.308-106.536 16.886c-1.032-.29-2.044-.558-3.063-.84a147.592 147.592 0 0 0 22.725-6.613c17.554-6.67 31.592-15.601 42.85-25.673"
          />
        </svg>
        <div className="bg-white p-4 rounded-md shadow-md">
          <Heading level={2} className="font-semibold text-2xl mb-4">
            Resend Unlock Instructions
          </Heading>
          <form action="/unlock" acceptCharset="UTF-8" method="post">
            <input
              type="hidden"
              name="authenticity_token"
              value={authenticityToken}
              autoComplete="off"
            />
            <div className="field">
              <Label htmlFor="user_email">Email</Label>
              <Input
                autoFocus="autofocus"
                autoComplete="email"
                defaultValue={email}
                type="email"
                name="user[email]"
                id="user_email"
              />
            </div>
            <div className="actions mt-4">
              <Button
                is="primary"
                className="w-full"
                size="full"
                data-disable-with="Resend unlock instructions"
              >
                Resend unlock instructions
              </Button>
              <Button href="/login" className="w-full mt-2" is="secondary">
                Log in
              </Button>
            </div>
          </form>
        </div>
        <div className="mt-4 flex flex-col gap-2 text-center">
          <a href="/client/signup" className="link">
            Sign up as a client
          </a>
          <a href="/stylist/signup" className="link">
            Sign up as a stylist
          </a>
        </div>
      </div>
    </div>
  )
}
