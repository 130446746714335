import React, { useState } from 'react'
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
  ExpressCheckoutElement,
} from '@stripe/react-stripe-js'
import Button from '../../../components/button'
import Notice from '../../../components/notice'

export default function CheckoutForm({ serviceId, clientSecret, clientEmail }) {
  const stripe = useStripe()
  const elements = useElements()
  const [payLoading, setPayLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleSubmit = async event => {
    event.preventDefault()
    setPayLoading(true)

    if (elements == null) {
      return
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message)
      return
    }

    const result = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${process.env.APP_PROTOCOL}://${process.env.APP_DOMAIN}/pay/${serviceId}`,
        receipt_email: clientEmail,
        payment_method_data: {
          billing_details: {
            email: clientEmail,
          },
        },
      },
    })

    if (result.error) {
      setErrorMessage(result.error.message)
    }
    setPayLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <AddressElement
        options={{
          mode: 'billing',
          fields: {
            phone: 'always',
          },
        }}
      />
      <ExpressCheckoutElement />
      <PaymentElement />
      <Button
        is="primary"
        size="lg"
        className="mt-4"
        loading={payLoading}
        disabled={!stripe || !elements}
      >
        Confirm payment
      </Button>
      {errorMessage && (
        <Notice type="error" className="mt-4">
          {errorMessage}
        </Notice>
      )}
    </form>
  )
}
