import React, { useState } from 'react'
import { Text, Heading } from '../components/typography'
import Empty from '../components/empty'
import AddService from './components/add-service'
import { ConfirmDialog } from '../components/dialog'
import notify from '../components/notify'
import { request } from '../utils/request'
import EditService from './components/edit-service'

const Service = ({ service, services, setServices }) => {
  const handleDelete = async closeModal => {
    try {
      const result = await request.delete(`/services/${service.id}`)
      if (result.data.name) {
        notify.success(`Your ${result.data.name} service has been deleted`)
        setServices(services.filter(s => s.id !== service.id))
      }
      closeModal()
    } catch (error) {
      const errorData = error.response.data
      if (errorData?.error) {
        notify.error(errorData.error)
      } else {
        notify.error('Something went wrong')
      }
    }
  }
  return (
    <div className="border border-gray-200 rounded-lg p-8">
      <div className="flex justify-between mb-4">
        <img
          src={service.preview_url}
          className="w-12 h-12 rounded-md shadow-md bg-gray-200"
        />
        <Text className="font-semibold text-xl">
          ${service.rate}
          {service.rate_type === 'hourly' && '/hr'}
        </Text>
      </div>
      <Heading level={4} size="lg" className="font-medium mb-2 text-brown-700">
        {service.name}
      </Heading>
      <Text>{service.description}</Text>
      <div className="mt-4 flex flex-col gap-2">
        <EditService
          service={service}
          services={services}
          setServices={setServices}
        />
        <ConfirmDialog
          is="secondary"
          triggerButtonContent="Delete"
          iconBefore="trash"
          onConfirm={handleDelete}
          caption="Are you sure you want to delete this service?"
        >
          <Text className="text-gray-500">
            This action cannot be undone. This will permanently delete the
            service from your account.
          </Text>
        </ConfirmDialog>
      </div>
    </div>
  )
}

export default function ServicesSettings({ currentUser }) {
  const [services, setServices] = useState(currentUser.account.services)
  return (
    <div>
      <div className="flex justify-between items-center">
        <Heading level={3} size="2xl" className="font-semibold mb-2">
          Services settings
        </Heading>
        <AddService
          account={currentUser.account}
          services={services}
          setServices={setServices}
        />
      </div>
      <Text className="text-gray-500">Manage your services</Text>
      <div className="mt-8">
        {services.length === 0 ? (
          <Empty title="No services yet">
            <Text className="text-gray-500 text-center">
              Add your first service to get started
            </Text>
          </Empty>
        ) : (
          <div className="grid gap-4 sm:grid-cols-2 xl:grid-cols-3">
            {services.map((service, index) => (
              <Service
                key={`${service.name}${index}`}
                service={service}
                services={services}
                setServices={setServices}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
