import React, { useState } from 'react'
import { Heading, Text } from '../components/typography'
import Button from '../components/button'
import Notice from '../components/notice'

export default function PaymentsSettings({ currentUser }) {
  const { account } = currentUser
  const [loading, setLoading] = useState(false)

  const handleConnect = () => {
    setLoading(true)
    return true
  }

  const checks = preRequisites([
    {
      check: currentUser.confirmed_at,
      error: (
        <Notice type="warning" title="Unconfirmed email">
          <Text className="mb-4">
            Your email address has not been confirmed. You need a confirmed
            email to set up payments
          </Text>
          <Button
            is="primary"
            href={`/resend-confirmation?email=${currentUser.email}`}
          >
            Resend confirmation email
          </Button>
        </Notice>
      ),
    },
    {
      check: account.email,
      error: (
        <Notice type="warning" title="Missing email">
          <Text className="mb-4">
            You need to add a business email address to your profile to set up
            payments
          </Text>
          <Button is="primary" href="/settings/profile">
            Add email address
          </Button>
        </Notice>
      ),
    },
    {
      check: !account.web_address.includes('://localhost'),
      error: (
        <Notice type="warning" title="Invalid web address">
          <Text className="mb-4">
            It appears the web address you have includes localhost. This can
            happen when you are testing your app locally. You need to add a
            valid web address to your profile to set up payments
          </Text>
          <Text>
            <strong>Current web address:</strong>
            <pre>{account.web_address}</pre>
          </Text>
        </Notice>
      ),
    },
    {
      check: account.stripe_connection,
      error: (
        <Button
          is="primary"
          loading={loading}
          onClick={handleConnect}
          href="/stripe/connect"
        >
          Connect your business for payments
        </Button>
      ),
    },
  ])

  return (
    <div>
      <Heading level={3} size="2xl" className="font-semibold mb-2">
        Payment settings
      </Heading>
      <Text className="text-gray-500">
        Set up your payments to start accepting payments from your customers
      </Text>
      <div className="mt-8">
        {checks || <p>Your account has been connected</p>}
      </div>
    </div>
  )
}

export function preRequisites(list) {
  const checks = list.map(item => {
    if (!item.check) {
      return item.error
    }
    return true
  })

  const error = checks.find(item => item !== true)

  return error ?? false
}
