import React from 'react'
import Label from '../components/label'
import { format } from '../utils/date'
import { Heading } from '../components/typography'

const showStatus = status => {
  if (status === 'created') return 'Awaiting response from stylist'

  return status
}

export default function ShowAppointment({ appointment }) {
  const date = new Date(appointment.starts_at)
  const day = format(date, 'E, MMM do')
  const time = format(date, 'h:mm aaa')
  return (
    <div className="mx-auto my-40 bg-white p-8 rounded shadow max-w-2xl">
      <Heading level={1} size="2xl" className="text-center">
        Appointment Details
      </Heading>
      <div className="my-6 text-center text-gray-600">
        Appointment status: <em>{showStatus(appointment.status)}</em>
      </div>
      <div className="my-8">
        <div className="flex flex-col gap-2">
          <div className="border-b border-gray-200 p-4">
            <Label>Service</Label>
            <div>{appointment.service.name}</div>
          </div>
          <div className="border-b border-gray-200 p-4">
            <Label>Stylist</Label>
            <div>{appointment.stylist.name}</div>
          </div>
          {appointment.location && (
            <div className="border-b border-gray-200 p-4">
              <Label>Location</Label>
              <div>{appointment.location}</div>
            </div>
          )}
          {appointment.starts_at && (
            <div className="border-b border-gray-200 p-4">
              <Label>Date</Label>
              <div>
                <time dateTime={appointment.starts_at}>
                  {day} {time}
                </time>
              </div>
            </div>
          )}
          {appointment.notes && (
            <div className="border-b border-gray-200 p-4">
              <Label>Notes from client</Label>
              <div>{appointment.notes}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
