import React from 'react'
import UnderConstruction from '../components/construction'
import { Text } from '../components/typography'

export default function ReportsIndex() {
  return (
    <UnderConstruction>
      <Text className="text-gray-400">
        We are currently working on providing a good reporting experience for
        you
      </Text>
    </UnderConstruction>
  )
}
