import React from 'react'
import cx from 'classnames'
import Button from '../../components/button'
import Icon from '../../components/icon'

export default function ProfileNavigation({ activeTab, setActiveTab }) {
  const tabs = [
    { text: 'portfolio', icon: 'grid' },
    { text: 'services', icon: 'scissors' },
    { text: 'reviews', icon: 'star' },
    { text: 'about', icon: 'feather' },
  ]
  return (
    <div className="border-t border-translucent_dark mx-4">
      <div className="flex justify-center">
        <div className="flex gap-8">
          {tabs.map(tab => (
            <Button
              key={tab.text}
              onClick={() => setActiveTab(tab.text)}
              className={cx(
                'p-3 md:py-4 md:px-8 text-gray-500 hover:text-gray-800 transition-colors duration-100',
                {
                  'text-gray-800 border-t-2 border-gray-800 font-medium':
                    activeTab === tab.text,
                }
              )}
              iconBefore={tab.icon}
            >
              <span className="hidden md:block capitalize">{tab.text}</span>
            </Button>
          ))}
        </div>
      </div>
    </div>
  )
}
