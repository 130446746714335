import React from 'react'
import { format } from '../utils/date'
/*
 * param {string} payload.event
 * param {string} payload.status
 * param {object} payload.data
 */
export default function notification(payload) {
  switch (payload.event) {
    case 'appointment_created': {
      let timeInfo = null
      if (payload.data.starts_at) {
        const date = new Date(payload.data.starts_at)
        const day = format(date, 'EEEE, MMMM d')
        const at = format(date, 'h:mm aaa')
        timeInfo = (
          <span>
            on {day} at {at}
          </span>
        )
      }
      return {
        icon: 'calendar',
        component: props => (
          <span {...props}>
            You have a new appointment{' '}
            {payload.data.client_name && `with ${payload.data.client_name} `}
            {timeInfo}
          </span>
        ),
        status: payload.status,
      }
    }
    case 'first_visit': {
      return {
        icon: 'disc',
        component: props => (
          <span {...props}>
            Welcome to StyleQ. Start customizing your{' '}
            <a href="/settings/profile" className="link">
              profile
            </a>
          </span>
        ),
        status: payload.status,
      }
    }
    default: {
      return {
        icon: 'disc',
        component: props => <span {...props}>{payload.message}</span>,
        status: payload.status,
      }
    }
  }
}
