import React from 'react'
import cx from 'classnames'
import { hasTailwindPrefix } from '../utils/tailwind'

export default function Label({
  children,
  className,
  plain = false,
  ...props
}) {
  const classes = cx(
    {
      'block font-medium text-gray-700': !plain,
      'mb-2': !hasTailwindPrefix('mb-', className) && !plain,
      'text-sm': !hasTailwindPrefix('text-', className) && !plain,
    },
    className
  )

  return (
    <label {...props} className={classes}>
      {children}
    </label>
  )
}
