import React from 'react'
import PoweredBadge from '../components/powered-badge'

export default function Pages({ showBage = true, children }) {
  return (
    <div>
      {children}
      {showBage && (
        <div className="grid place-content-center px-4 py-8">
          <PoweredBadge />
        </div>
      )}
    </div>
  )
}
