import React from 'react'
import { Text, Heading } from '../../components/typography'
import Empty from '../../components/empty'
import { getCurrencySymbol } from '../../utils/numbers'

const Service = ({ service }) => {
  return (
    <div className="bg-white border border-gray-200 rounded-lg p-8">
      <div className="flex justify-between mb-4">
        <img
          src={service.preview_url}
          className="w-12 h-12 rounded-md shadow-md bg-gray-200 object-cover object-center"
        />
        <Text className="font-semibold text-xl">
          {getCurrencySymbol(service.currency)}
          {service.rate}
          {service.rate_type === 'hourly' && '/hr'}
        </Text>
      </div>
      <Heading level={4} size="lg" className="font-medium mb-2 text-brown-700">
        {service.name}
      </Heading>
      <Text>{service.description}</Text>
    </div>
  )
}

export default function Services({ account }) {
  return (
    <div>
      {account.services.length === 0 ? (
        <Empty title="There are no services available yet" />
      ) : (
        <div className="py-4 grid gap-4 sm:grid-cols-2 xl:grid-cols-3">
          {account.services.map((service, index) => (
            <Service key={`${service.name}${index}`} service={service} />
          ))}
        </div>
      )}
    </div>
  )
}
