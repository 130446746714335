import React from 'react'

export default function Footer() {
  const date = new Date()
  return (
    <footer className="wrapper py-8 text-gray-600">
      &copy; {date.getFullYear()} StyleQ
    </footer>
  )
}
