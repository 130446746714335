import React from 'react'
import cx from 'classnames'

export default function ProgressBar({ value, fillColor, className }) {
  return (
    <div
      data-name="track"
      className="h-1 bg-translucent_dark rounded-lg overflow-hidden"
    >
      <div
        data-name="fill"
        style={{ width: `${value}%`, background: fillColor }}
        className={cx('h-full', className)}
      />
    </div>
  )
}
