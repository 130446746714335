import React, { useState } from 'react'
import { Text, Heading } from '../components/typography'
import SettingGroup from './components/setting-group'
import Input from '../components/input'
import SelectMenu from '../components/select-menu'
import Label from '../components/label'
import Button from '../components/button'
import { useUploader } from '../components/uploader'
import Avatar from '../components/avatar'
import Notice from '../components/notice'
import { request } from '../utils/request'

export default function AccountSettings({ authenticityToken, currentUser }) {
  const [avatar, setAvatar] = useState(currentUser.avatar)
  /**
   * TODO: Fetch timezones from the server
   * and add a timezone for each user/account.
   */
  const timezones = []
  const [timezone, setTimezone] = useState(
    currentUser.timezone || '(GMT -06:00) Central Time (US & Canada)'
  )
  const onAvatarSave = data => {
    setAvatar(data.url)
    request.put('/settings/account', {
      user: {
        avatar_url: data.url,
      },
    })
  }
  const Uploader = useUploader({ onSave: onAvatarSave })
  const dataDescription =
    currentUser.role === 'stylist'
      ? 'Email and other information to manage your account. This is different from your business profile.'
      : 'Email and other information to manage your account.'

  return (
    <div>
      <Heading level={3} size="2xl" className="font-semibold mb-2">
        User account settings
      </Heading>
      <Text className="text-gray-500">
        Update your user settings. Changes here are not automatically saved
        until you click the Update button.
      </Text>
      <div className="mt-8">
        <SettingGroup
          title="Profile picture"
          description="We will try to grab your gravatar profile picture automatically. You can also upload a custom profile picture here"
        >
          <div className="flex gap-5">
            <Avatar src={avatar} shape="rounded" className="object-cover" />
            <div className="flex-auto">
              <Uploader.Component />
            </div>
          </div>
        </SettingGroup>
        <form
          id="edit_user"
          action="/settings/account"
          acceptCharset="UTF-8"
          method="post"
        >
          <input type="hidden" name="_method" value="put" autoComplete="off" />
          <input
            type="hidden"
            name="authenticity_token"
            value={authenticityToken}
            autoComplete="off"
          />

          <SettingGroup title="User Information" description={dataDescription}>
            <div>
              <Label htmlFor="user_name">Name</Label>
              <Input
                autoComplete="name"
                defaultValue={currentUser.name}
                name="user[name]"
                id="user_name"
                placeholder="Name"
              />
            </div>
            <div>
              <Label htmlFor="user_email">Email</Label>
              <Input
                autoComplete="email"
                type="email"
                defaultValue={currentUser.email}
                name="user[email]"
                id="user_email"
                placeholder="Email"
              />
            </div>
            {currentUser.unconfirmed_email && (
              <Notice type="warning" title="Unconfirmed email">
                <Text>
                  Your email address {currentUser.unconfirmed_email} has not
                  been confirmed
                </Text>
                <Button
                  is="warning"
                  href={`/resend-confirmation?email=${currentUser.unconfirmed_email}`}
                  className="mt-4 bg-translucent_dark py-2 px-4 rounded-md"
                >
                  Resend confirmation email
                </Button>
              </Notice>
            )}
            {!currentUser.confirmed_at && (
              <Notice type="warning" title="Unconfirmed email">
                <Text className="mb-4">
                  Your email address has not been confirmed
                </Text>
                <Button
                  is="primary"
                  type="button"
                  href={`/resend-confirmation?email=${currentUser.email}`}
                >
                  Resend confirmation email
                </Button>
              </Notice>
            )}
            <div>
              <Label htmlFor="user_phone_number">Phone number</Label>
              <Input
                autoComplete="tel"
                defaultValue={currentUser.phone_number}
                name="user[phone_number]"
                id="user_phone_number"
                placeholder="Phone number"
              />
            </div>
          </SettingGroup>

          <SettingGroup
            title="Default timezone"
            description="Set your default timezone. This will be used to display dates and times in your local timezone"
          >
            <SelectMenu
              defaultOption={timezone}
              options={timezones.map(tz => ({
                label: `${tz.name} - ${tz.offset}`,
                value: tz.value,
                selected: tz.value === timezone,
              }))}
              onChange={value => setTimezone(value)}
            />
          </SettingGroup>

          <SettingGroup
            title="Password"
            description="Change to a secure password. Leave blank to retain old password"
          >
            <Input
              autoComplete="new-password"
              type="password"
              name="user[password]"
              id="user_password"
              placeholder="Password"
            />
            <Input
              autoComplete="new-password"
              type="password"
              name="user[password_confirmation]"
              id="user_password_confirmation"
              placeholder="Password confirmation"
            />
          </SettingGroup>

          <SettingGroup
            title="Current password"
            description="We need your current password to confirm your changes"
            required
          >
            <Input
              autoComplete="current-password"
              type="password"
              name="user[current_password]"
              id="user_current_password"
              required
            />
          </SettingGroup>

          <Button is="primary">Update</Button>
        </form>
      </div>
    </div>
  )
}
