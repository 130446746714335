import React from 'react'
import { Text } from './typography'
import cx from 'classnames'

export function SimpleEmpty({ children, className, ...props }) {
  return (
    <div
      className={cx('grid place-content-center h-full', className)}
      {...props}
    >
      {children || 'No data to display'}
    </div>
  )
}

export default function Empty({ title, children }) {
  return (
    <div className="grid place-content-center py-28 px-8">
      <div className="flex flex-col items-center gap-4 text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 60 60"
          fill="currentColor"
          className="w-20 text-brown-600"
        >
          <path d="m56.91 46.553-2.001-4a1.006 1.006 0 0 0-1.12-.528l-12.906 2.978-1.96-4.41a.943.943 0 0 0-.073-.102.975.975 0 0 0 .149-.633 9.198 9.198 0 0 0-8.993-7.855V30h4.001a3 3 0 1 0 0-6H18.57c-.59-5.106-1.598-6.446-1.86-6.707a1 1 0 0 0-.707-.293h-3.22l-.505-2.016a2.79 2.79 0 0 0 2.125-1.148 3.118 3.118 0 0 0 .458-2.765l-.62-2A2.868 2.868 0 0 0 11.52 7H9.86a2.794 2.794 0 0 0-2.262 1.164 3.118 3.118 0 0 0-.457 2.765l.62 2a2.875 2.875 0 0 0 2.453 2.049L10.719 17H10a1 1 0 0 0-.64 1.769c.152.35.64 1.967.64 8.231 0 8.066 3.855 12.355 5.037 13.478a3.084 3.084 0 0 0 .912.592 29.368 29.368 0 0 0 9.057 1.83V44h-3.001a1 1 0 0 0-.833.445l-2 3a1 1 0 0 0 1.664 1.11L22.54 46h2.466v1a1 1 0 0 0-1 1v3h-4.002a5.007 5.007 0 0 0-5.002 5 1 1 0 0 0 1 1H40.01a1 1 0 0 0 1-1 5.007 5.007 0 0 0-5-5h-4.002v-3a1 1 0 0 0-1-1v-4.106a19.383 19.383 0 0 0 6.182-1.277l3.907 8.79a1.037 1.037 0 0 0 1.124.571l14.004-3a1 1 0 0 0 .685-1.425ZM9.671 12.336l-.62-2a1.112 1.112 0 0 1 .157-.986.813.813 0 0 1 .652-.35h1.658a.882.882 0 0 1 .81.664l.62 2a1.112 1.112 0 0 1-.158.986.813.813 0 0 1-.651.35H10.48a.882.882 0 0 1-.81-.664ZM19.002 26h15.005a1 1 0 1 1 0 2H19.003Zm9.004 4v1.999l-9.007-.007c-.006-.698-.019-1.357-.036-1.992ZM12 27a41.91 41.91 0 0 0-.508-8h3.949c.44.937 1.483 4.087 1.556 13.008a1.978 1.978 0 0 0 .106.596.932.932 0 0 0-.063.121l-1.521 5.322A17.172 17.172 0 0 1 12 27Zm26.837 28H17.174a3.006 3.006 0 0 1 2.83-2h16.004a3.006 3.006 0 0 1 2.83 2Zm-12.833-4v-2h4.001v2Zm3.001-8v4h-2v-4Zm-1-2a31.967 31.967 0 0 1-10.797-1.585l1.555-5.439a1.938 1.938 0 0 0 .234.024h10.937a7.195 7.195 0 0 1 6.97 5.538c-.896.484-3.388 1.462-8.9 1.462Zm14.74 7.82-.519-2.074 11.244-2.595 1.071 2.141Z" />
        </svg>
        <Text size="lg" className="font-semibold text-gray-600">
          {title || 'No content has been created yet'}
        </Text>
        {children}
      </div>
    </div>
  )
}
