import React, { useRef, useEffect } from 'react'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import WidgetHeader from '../widget-header'

export default function ClientCalendar() {
  const calendarRef = useRef()

  useEffect(() => {
    const calendarEl = calendarRef.current
    const calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
      initialView: 'listWeek',
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: 'listWeek,timeGridWeek,dayGridMonth',
      },
      events: [
        {
          id: '1',
          resourceId: 'b',
          start: '2023-06-25T11:00:00',
          end: '2023-06-25T13:00:00',
          title: 'Fade with Sport clips',
        },
        {
          id: '2',
          resourceId: 'c',
          start: '2023-06-25T15:00:00',
          end: '2023-06-25T17:00:00',
          title: 'Braid with Keisha',
        },
        {
          id: '3',
          resourceId: 'd',
          start: '2023-08-26',
          end: '2023-06-26',
          title: 'Hair dye appointment',
        },
        {
          id: '4',
          resourceId: 'e',
          start: '2023-06-27T03:00:00',
          end: '2023-06-27T08:00:00',
          title: 'Tattoo with Shawn',
        },
        {
          id: '5',
          resourceId: 'f',
          start: '2023-07-07T00:30:00',
          end: '2023-07-07T02:30:00',
          title: 'Spa with Neroli',
        },
      ],
      resources: [
        { id: 'a', title: 'Auditorium A' },
        { id: 'b', title: 'Auditorium B', eventColor: 'green' },
        { id: 'c', title: 'Auditorium C', eventColor: 'orange' },
        {
          id: 'd',
          title: 'Auditorium D',
          children: [
            { id: 'd1', title: 'Room D1' },
            { id: 'd2', title: 'Room D2' },
          ],
        },
        { id: 'e', title: 'Auditorium E' },
        { id: 'f', title: 'Auditorium F', eventColor: 'red' },
        { id: 'g', title: 'Auditorium G' },
        { id: 'h', title: 'Auditorium H' },
        { id: 'i', title: 'Auditorium I' },
        { id: 'j', title: 'Auditorium J' },
      ],
    })
    calendar.render()
  }, [])

  return (
    <div className="bg-white shadow rounded-lg p-4 flex flex-col gap-5">
      <WidgetHeader title="Upcoming appointments">
        <a href="/appointments">View previous appointments</a>
      </WidgetHeader>
      <div id="calendar" ref={calendarRef}></div>
    </div>
  )
}
