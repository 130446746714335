import React from 'react'
import cx from 'classnames'
import { Text } from './typography'

export default function Notice({ type, title, children, className }) {
  const classes = cx('rounded-md p-4', className, {
    'bg-green-50 text-green-800': type === 'success',
    'bg-red-50 text-red-800': type === 'error',
    'bg-yellow-50 text-yellow-800': type === 'warning',
    'bg-blue-50 text-blue-800': type === 'info',
  })

  return (
    <div className={classes}>
      {title && (
        <Text size="base" className="font-semibold mb-2">
          {title}
        </Text>
      )}
      {children}
    </div>
  )
}
