import React from 'react'

export default function ErrorTest() {
  return (
    <div>
      <h1>Test</h1>
      <button
        className="bg-white shadow border border-gray-200 rounded p-3"
        onClick={() => methodDoesNotExist()}
      >
        Click to trigger an error
      </button>
    </div>
  )
}
