import React, { useEffect, useReducer } from 'react'
import { Text, Heading } from '../components/typography'
import Checkbox from '../components/checkbox'
import Label from '../components/label'
import Input from '../components/input'
import Button from '../components/button'
import notify from '../components/notify'
import { request, handleRequestFailure } from '../utils/request'

const updateCalendarEvent = async calendar => {
  try {
    const response = await request.put(`/calendar_events/1`, {
      calendar_event: {
        calendar,
      },
    })
    notify.success('Your calendar was updated')
    return response.data.calendar
  } catch (error) {
    handleRequestFailure(error)
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return {
        ...state,
        [action.day]: [...state[action.day], action.slot],
      }
    case 'update':
      console.log({ state })
      updateCalendarEvent(state)
      return {
        ...state,
        [action.day]: state[action.day].map((slot, index) => {
          if (index === action.index) {
            return action.slot
          }
          return slot
        }),
      }
    case 'remove':
      return {
        ...state,
        [action.day]: state[action.day].filter(
          (_, index) => index !== action.index
        ),
      }
    case 'reset':
      return {
        ...state,
        [action.day]: [],
      }
    default:
      throw new Error()
  }
}
export default function AvailabilitySettings({ calendar }) {
  const [timeSlots, dispatch] = useReducer(reducer, calendar)

  return (
    <div>
      <div className="flex justify-between items-center">
        <Heading level={3} size="2xl" className="font-semibold mb-2">
          Availability settings
        </Heading>
      </div>
      <Text className="text-gray-500">
        Set your schedules to let your clients know when you are available
      </Text>
      <div className="mt-8">
        <div className="flex flex-col divide-y divide-gray-200">
          {Object.keys(timeSlots).map((day, index) => {
            const isDaySelected = timeSlots[day].length > 0
            return (
              <div
                key={`${day}${index}`}
                className="flex flex-col md:flex-row gap-2 md:items-start py-4 first:pt-0 last:pb-0 border-solid"
              >
                <div className="flex-1 h-12 md:w-40 flex items-center gap-4">
                  <Checkbox
                    id={day}
                    checked={isDaySelected}
                    onChange={() =>
                      dispatch({
                        type: isDaySelected ? 'reset' : 'add',
                        day,
                        slot: ['00:00', '00:00'],
                      })
                    }
                  />
                  <Label plain htmlFor={day}>
                    {day}
                  </Label>
                </div>
                <div className="flex-auto md:w-44 flex flex-col gap-4">
                  {timeSlots[day].length === 0 && (
                    <div
                      key={`${day}${index}`}
                      className="flex items-center gap-2"
                    >
                      <Input type="time" />
                      <Text>-</Text>
                      <Input type="time" />
                      <Button icon="trash" title="Remove time slot" />
                    </div>
                  )}
                  {timeSlots[day].map((slot, index) => (
                    <div
                      key={`${day}${index}`}
                      className="flex items-center gap-2"
                    >
                      <Input
                        type="time"
                        value={slot[0]}
                        onChange={event =>
                          dispatch({
                            type: 'update',
                            day,
                            index,
                            slot: [event.target.value, slot[1]],
                          })
                        }
                      />
                      <Text>-</Text>
                      <Input
                        type="time"
                        value={slot[1]}
                        onChange={event =>
                          dispatch({
                            type: 'update',
                            day,
                            index,
                            slot: [slot[0], event.target.value],
                          })
                        }
                      />
                      <Button
                        icon="trash"
                        title="Remove time slot"
                        onClick={() => dispatch({ type: 'remove', day, index })}
                      />
                    </div>
                  ))}
                </div>
                <div className="flex-1 flex gap-4 md:justify-end">
                  <Button
                    iconBefore="plus"
                    is="secondary"
                    onClick={() =>
                      dispatch({ type: 'add', day, slot: ['00:00', '00:00'] })
                    }
                  >
                    Add time slot
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
