import React from 'react'
import { Heading, Text } from '../components/typography'
import Button from '../components/button'

export default function ErrorBoundaryFallback(props) {
  console.log(props)
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <Heading level={1} size="3xl" className="font-semibold text-brown-900">
        An error occurred
      </Heading>
      <Text className="text-gray-500">
        The React application encountered an error
      </Text>
      <div className="my-8 px-8">
        {props.error && (
          <Text className="text-red-600 px-4 mb-4">
            {props.error.toString()}
          </Text>
        )}
        {props.componentStack && (
          <pre className="w-full whitespace-pre-wrap overflow-auto text-sm text-gray-600 bg-gray-200 p-3">
            {props.componentStack.toString()}
          </pre>
        )}
      </div>

      <Text className="text-gray-500 font-semibold">
        Please refresh the page or try again
      </Text>
      <div className="flex gap-4 mt-4">
        {props.resetError && (
          <Button
            className="bg-white shadow border border-gray-200 rounded p-3"
            onClick={props.resetError}
          >
            Try again
          </Button>
        )}
        <Button
          className="bg-white shadow border border-gray-200 rounded p-3"
          onClick={() => window.location.reload()}
        >
          Refresh the page
        </Button>
      </div>
    </div>
  )
}
