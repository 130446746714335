import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

const notify = new Notyf({
  duration: 5000,
  dismissible: true,
  types: [
    {
      type: 'info',
      background: '#3B82F6',
    },
    {
      type: 'warn',
      background: '#F59E0B',
    },
  ],
})

export default notify
