import Input from '../input'

describe('Input', () => {
  describe('patterns', () => {
    it('matches telephone numbers', () => {
      const regexString = Input.patterns.phone
      const pattern = new RegExp(regexString)
      expect(pattern.test('+12345678901')).toBe(true)
      expect(pattern.test('+1-234-567-8901')).toBe(true)
      expect(pattern.test('234-567-8901')).toBe(true)
      expect(pattern.test('2345678901')).toBe(true)
      expect(pattern.test('(234) 567 8901')).toBe(true)
      expect(pattern.test('567-8901')).toBe(true)
      expect(pattern.test('234 567 8901')).toBe(true)
      expect(pattern.test('PH: 234 567 8901')).not.toBe(true)
    })

    it('matches slugs', () => {
      const regexString = Input.patterns.slug
      const pattern = new RegExp(regexString)
      expect(pattern.test('slug')).toBe(true)
      expect(pattern.test('slug-2')).toBe(true)
      expect(pattern.test('sl')).toBe(true)
      // we allow for the pattern to allow for a single character
      // but apply max length validation of 2 to the input
      expect(pattern.test('s')).toBe(true)
      expect(pattern.test('-s-')).not.toBe(true)
      expect(pattern.test(';xo;')).not.toBe(true)
      expect(pattern.test('slug;')).not.toBe(true)
    })

    it('matches links', () => {
      const regexString = Input.patterns.link
      const pattern = new RegExp(regexString)
      expect(pattern.test('google.com')).toBe(true)
      expect(pattern.test('www.google.com')).toBe(true)
      expect(pattern.test('https://google.com')).toBe(true)
      expect(pattern.test('https://google.com/')).toBe(true)
      expect(pattern.test('https://google.com/search')).toBe(true)
      expect(pattern.test('https://google.com/search?q=hello')).toBe(true)
      expect(pattern.test('https://google.com/search?q=hello+world')).toBe(true)
      expect(pattern.test('https://google.com/search?q=hello+world#top')).toBe(
        true
      )
    })
  })
})
