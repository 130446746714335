import React, { useRef, useEffect } from 'react'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import WidgetHeader from '../widget-header'
import { SimpleEmpty } from '../../../components/empty'
import { Text } from '../../../components/typography'

export default function StylistCalendarWidget({ appointments }) {
  const calendarRef = useRef()
  const noAppointments = appointments.length <= 0

  useEffect(() => {
    if (noAppointments) return
    const calendarEl = calendarRef.current
    const calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
      initialView: 'listWeek',
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: 'listWeek,timeGridWeek,dayGridMonth',
      },
      events: appointments.map(appointment => ({
        id: appointment.id,
        resourceId: appointment.client_id,
        start: appointment.starts_at,
        title: appointment.title || appointment.generated_title,
      })),
    })
    calendar.render()
  }, [appointments])

  return (
    <div className="bg-white shadow rounded-lg p-4 flex flex-col gap-4">
      <WidgetHeader title="Calendar">
        <a href="/bookings">Manage your bookings</a>
      </WidgetHeader>
      {noAppointments ? (
        <SimpleEmpty className="min-h-[400px]">
          <Text>You have no confirmed appointments yet</Text>
        </SimpleEmpty>
      ) : (
        <div id="calendar" ref={calendarRef}></div>
      )}
    </div>
  )
}
