import React from 'react'
import { Heading, Text } from '../components/typography'

export default function NotFound({ error }) {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <Heading level={1} size="3xl" className="font-semibold text-brown-900">
        404
      </Heading>
      <Text className="text-gray-500">{error || 'Page not found'}</Text>
    </div>
  )
}
