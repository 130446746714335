import axios from 'axios'
import notify from '../components/notify'

const getCSRFToken = () => {
  const token = document.querySelector('meta[name="csrf-token"]')
  return token ? token.content : null
}

export const baseURL = `${process.env.APP_PROTOCOL}://${process.env.APP_DOMAIN}`

export const request = axios.create({
  baseURL,
  timeout: 9000,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': getCSRFToken(),
  },
})

export const handleRequestFailure = (
  error,
  messageCallback,
  options = {
    notify: true,
  }
) => {
  const response = error?.response
  if (!response) return

  const message = response.data?.message
  if (!message) return

  if (messageCallback) {
    messageallback(message)
  }

  if (options.notify) {
    notify.error(message)
  }
}
