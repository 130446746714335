import React, { useState } from 'react'
import Avatar from '../../../components/avatar'
import Icon from '../../../components/icon'
import { Menu, MenuItem } from '../../../components/dropdown'
import notificationLocale from '../../../locales/notification.en'

export default function HeaderWidget({
  profileName,
  accountName,
  avatar,
  role,
  notifications,
}) {
  const [online, setOnline] = useState(false)

  return (
    <div className="bg-white shadow rounded-lg p-4">
      <div className="flex flex-col md:flex-row gap-5 items-center">
        <Avatar src={avatar} size="sm" className="object-cover" />
        <div className="flex flex-col justify-center gap-1">
          <div className="font-semibold">{profileName || 'Your name'}</div>
          {accountName && (
            <div className="text-gray-500 text-sm">{accountName}</div>
          )}
        </div>
        <div className="md:ml-auto flex flex-col md:flex-row gap-5">
          {role === 'client' && (
            <form className="relative">
              <Icon
                name="search"
                className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
              />
              <input
                type="text"
                placeholder="Search for stylists"
                className="bg-gray-100 border-0 rounded-2xl w-60 pl-10"
              />
            </form>
          )}
          {/* {role === 'stylist' && (
            <Toggle
              checked={online}
              buttons={{
                checked: {
                  label: 'Online',
                  className: cx('relative', {
                    'text-gray-100': online,
                    'text-gray-800': !online,
                  }),
                },
                unchecked: {
                  label: 'Offline',
                  className: cx('relative', {
                    'text-gray-100': !online,
                    'text-gray-800': online,
                  }),
                },
              }}
              thumbClassName={online ? 'bg-green-500' : 'bg-red-600'}
              className="bg-gray-100"
              onClick={() => setOnline(!online)}
            />
          )} */}
          <div className="flex gap-5 justify-center">
            <Menu
              title="View notifications"
              className="relative grid place-content-center bg-gray-100 hover:bg-gray-200 w-10 h-10 rounded-full"
              iconBefore="bell"
              label={
                notifications.length > 0 && (
                  <div className="absolute w-2 h-2 top-0 right-0 bg-red-600 rounded-full pointer-events-none">
                    <span className="absolute left-0 w-full h-full inline-flex rounded-full bg-red-600 animate-ping -indent-80 overflow-hidden">
                      You have unread notifications
                    </span>
                  </div>
                )
              }
            >
              {notifications.length <= 0 && (
                <MenuItem label="You have no notifications" disabled />
              )}
              {notifications.map((n, index) => {
                const notification = notificationLocale(n)
                const Component = notification.component
                return (
                  <MenuItem
                    key={n.id}
                    label={
                      <div className="flex gap-8 items-center">
                        {notification.icon && (
                          <div className="w-2 h-2 grid place-content-center">
                            <Icon
                              name={notification.icon}
                              className="text-brown-600"
                            />
                          </div>
                        )}
                        <Component className="flex-auto" />
                      </div>
                    }
                  />
                )
              })}
            </Menu>
          </div>
        </div>
      </div>
    </div>
  )
}
