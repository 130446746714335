import React, { useState } from 'react'
import { Heading } from '../components/typography'
import Icon from '../components/icon'
import Button from '../components/button'
import Avatar from '../components/avatar'
import Reviews from './components/reviews'
import ProfileNavigation from './components/navigation'
import About from './components/about'
import Portfolio from './components/portfolio'
import Services from './components/services'
import { linkify } from '../utils/strings'

export default function Profile({ account, env }) {
  const [activeTab, setActiveTab] = useState('portfolio')
  const socialLinks = [
    { href: account.website_url, icon: 'globe' },
    { href: account.facebook_url, icon: 'facebook' },
    { href: account.instagram_url, icon: 'instagram' },
    { href: account.twitter_url, icon: 'twitter' },
    { href: account.youtube_url, icon: 'youtube' },
  ]
  return (
    <div className="wrapper pt-16">
      <div className="flex flex-col gap-8 text-center items-center">
        <Avatar
          src={account.logo || 'https://picsum.photos/seed/pup/128'}
          size="xl"
          shape="rounded"
        />
        <Heading level={1} size="3xl" className="font-semibold text-brown-900">
          {account.name || 'Your Business name'}
        </Heading>
        <div className="flex flex-wrap justify-center gap-5">
          {socialLinks
            .filter(link => link.href)
            .map(link => (
              <Button
                key={link.href}
                href={linkify(link.href)}
                className="bg-brown-200 hover:bg-brown-300 transition-colors duration-100 text-gray-800 rounded-full p-3 grid place-content-center"
              >
                <Icon name={link.icon} className="h-6 w-6" />
              </Button>
            ))}
        </div>
      </div>
      <div className="my-12 flex flex-wrap gap-4 justify-center">
        {account.stripe_connection && (
          <Button
            is="primary"
            href={`${
              env === 'development' ? `/p/${account.slug || account.id}` : ''
            }/book`}
          >
            Book appointment
          </Button>
        )}
        {account.email && (
          <Button is="secondary" href={`mailto:${account.email}`}>
            Send a message
          </Button>
        )}
      </div>
      <div>
        <ProfileNavigation activeTab={activeTab} setActiveTab={setActiveTab} />
        <ProfileContent activeTab={activeTab} account={account} />
      </div>
    </div>
  )
}

function ProfileContent({ activeTab, account }) {
  return {
    portfolio(account) {
      return <Portfolio account={account} />
    },
    services(account) {
      return <Services account={account} />
    },
    reviews(account) {
      return <Reviews account={account} />
    },
    about(account) {
      return <About account={account} />
    },
  }[activeTab](account)
}
