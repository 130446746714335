import React from 'react'
import StylistCalendarWidget from './widgets/stylist-calendar'
import StylistClients from './widgets/stylist-clients'
import StylistRevenue from './widgets/stylist-revenue'

export default function StylistWidgets({ appointments, clients, revenue }) {
  return (
    <div className="flex flex-col gap-4">
      <div className="grid lg:grid-cols-2 gap-4">
        <StylistRevenue revenue={revenue} />
        <StylistClients clients={clients} />
      </div>
      <StylistCalendarWidget appointments={appointments} />
    </div>
  )
}
