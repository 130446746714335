import React, { forwardRef, useState } from 'react'
import cx from 'classnames'
import { hasTailwindPrefix } from '../utils/tailwind'
import useForwardedRef from '../hooks/use-forwardedref'
import { useDebouncedCallback } from 'use-debounce'

export const Addon = ({ children, className }) => {
  const classes = cx(
    'w-full h-full grid place-content-center',
    {
      'bg-gray-200': !hasTailwindPrefix('bg', className),
      'px-3 py-2 sm:py-0': !hasTailwindPrefix('p-', className),
      'font-medium': !hasTailwindPrefix('font-', className),
    },
    className
  )
  return <div className={classes}>{children}</div>
}

const Input = forwardRef(function Input(
  {
    type = 'text',
    readOnly = false,
    className,
    onChange,
    before,
    after,
    responsive,
    ...props
  },
  ref
) {
  const inputRef = useForwardedRef(ref)
  const [isValid, setIsValid] = useState(true)
  const Component = type === 'textarea' ? 'textarea' : 'input'
  const classes = cx(
    'relative flex border rounded-md w-full overflow-hidden',
    {
      'flex-col sm:flex-row': responsive,
      'bg-gray-100': readOnly,
      'border-red-500': !isValid,
      'border-gray-300': isValid,
    },
    className
  )
  const inputClasses = cx('flex-1 border-0', {
    'min-h-[120px]': type === 'textarea',
    'h-12': type !== 'textarea',
    'px-2': !hasTailwindPrefix('p-', className),
    'bg-gray-100 cursor-not-allowed': readOnly,
  })

  const handleChange = event => {
    const valid = event.target.checkValidity()
    setIsValid(valid)
    if (onChange) onChange(event, valid)
  }

  useDebouncedCallback(() => {
    if (!isValid) inputRef.current.reportValidity()
  }, 1500)()

  return (
    <div className={classes}>
      {before && (
        <div className="relative inset-y-0 left-0 flex items-center select-none">
          {before}
        </div>
      )}
      <Component
        {...props}
        type={type}
        ref={inputRef}
        readOnly={readOnly}
        className={inputClasses}
        onChange={handleChange}
      />
      {after && (
        <div className="absolute inset-y-0 right-0 flex items-center select-none">
          {after}
        </div>
      )}
    </div>
  )
})

// This uses the raw string syntax to avoid having to escape backslashes
Input.patterns = {
  phone: String.raw`^(?:\+)?[\d\(\)]+[\d\-\(\) ]+$`,
  slug: String.raw`^\w[\w\-]*$`,
  url: String.raw`^(?:https?)[\w\-\.\/:]+[\?\=\w\+\#]*$`,
  link: String.raw`^(?:https?)?[\w\-\.\/:]+[\?\=\w\+\#]*$`,
}

export default Input
