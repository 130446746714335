import React, { useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const ListContext = createContext({
  view: 'table',
  setView: () => {},
  filters: null,
  columns: null,
})

export function List({ children, view = 'table', columns }) {
  const [viewAs, setViewAs] = useState(view)

  return (
    <ListContext.Provider
      value={{
        view: viewAs,
        setView: setViewAs,
        columns,
      }}
    >
      {children}
    </ListContext.Provider>
  )
}

export function ListItems({ children }) {
  const list = useContext(ListContext)
  return (
    <div
      className={cx({
        'bg-white rounded-md shadow-md overflow-hidden': list.view === 'table',
      })}
    >
      {React.Children.map(children, (child, index) => (
        <div
          key={index}
          className={cx('bg-white border-gray-200', {
            'border-t': list.view === 'table' && index !== 0,
            'shadow rounded': list.view === 'grid',
          })}
        >
          {child}
        </div>
      ))}
    </div>
  )
}

export function ListItem({ children, className, ...props }) {
  const list = useContext(ListContext)
  return (
    <div
      className={cx({
        'grid gap-1 p-4': list.view === 'table',
      })}
      style={{
        gridTemplateColumns: list.view === 'table' ? list.columns : null,
      }}
      {...props}
    >
      {children}
    </div>
  )
}

export function ListHeader({ children, className, ...props }) {
  const list = useContext(ListContext)
  return (
    <div
      className={cx(
        'grid gap-1 p-4 font-medium text-gray-700 bg-gray-100',
        className
      )}
      style={{
        gridTemplateColumns: list.view === 'table' ? list.columns : null,
      }}
      {...props}
    >
      {children}
    </div>
  )
}

export function ListSection({ children, className, ...props }) {
  return (
    <div
      className={cx('flex flex-col justify-center gap-2', className)}
      {...props}
    >
      {children}
    </div>
  )
}

List.propTypes = {
  view: PropTypes.oneOf(['table', 'grid']),
}
