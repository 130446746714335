import React, { useState } from 'react'
import Logo from '../../components/logo'
import Icon from '../../components/icon'
import cx from 'classnames'
import Hamburger from '../../components/hamburger'

const NavItem = ({ href, icon, children, url }) => {
  const isLogout = href === '/logout'
  const matches =
    url.length === 1
      ? href === url
      : href.slice(0, 6).startsWith(url.slice(0, 6))
  const className = cx('flex gap-3 px-3 py-3 transition-colors rounded-md', {
    'bg-red-600 hover:bg-red-700 text-white': isLogout,
    'hover:text-gray-900 hover:bg-gray-100': !isLogout,
    'text-blue-500 font-semibold': matches,
    'text-gray-500': !matches,
  })
  return (
    <a href={href} className={className} aria-current={matches}>
      {icon && <Icon name={icon} />}
      <span>{children}</span>
    </a>
  )
}

function isActive(href, url) {
  if (href === '/') return url === '/'
  return url.startsWith(href)
}

export default function Panel({ currentUser, url }) {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <div>
      <div className="bg-white shadow rounded-lg sticky top-2 lg:w-64">
        <div className="lg:grid py-4 lg:py-8 px-4">
          <div className="flex justify-between">
            <a href="/" className="inline-block px-3 mt-2">
              <Logo width="110" fill="#011627" />
            </a>
            <Hamburger
              className="lg:hidden bg-brown-600 text-white"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              Menu
            </Hamburger>
          </div>
          <div className={cx('lg:block py-4 lg:py-0', { hidden: !menuOpen })}>
            <div className="lg:mt-32 mb-3 lg:mb-56 flex flex-col gap-3">
              <NavItem href="/" url={url} icon="home">
                Home
              </NavItem>
              <NavItem href="/bookings" url={url} icon="calendar">
                Bookings
              </NavItem>
              {currentUser.role === 'stylist' && (
                <>
                  <NavItem href="/clients" url={url} icon="users">
                    Clients
                  </NavItem>
                  {/* <NavItem href="/reports" url={url} icon="pie-chart">
                    Reports
                  </NavItem> */}
                </>
              )}
              <NavItem href="/settings/profile" url={url} icon="settings">
                Settings
              </NavItem>
            </div>
            <div className="self-end flex flex-col gap-3">
              <NavItem
                href="mailto:support@bookstyleq.com"
                url={url}
                icon="help"
              >
                Help & Support
              </NavItem>
              <NavItem href="/logout" url={url} icon="logout">
                Log out
              </NavItem>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
