import React, { useState } from 'react'
import { request } from '../../utils/request'
import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogTrigger,
  DialogHeading,
  DialogActions,
  DialogAction,
} from '../../components/dialog'
import Input, { Addon } from '../../components/input'
import Label from '../../components/label'
import notify from '../../components/notify'
import Avatar from '../../components/avatar'
import { useUploader } from '../../components/uploader'

export default function EditService({ service, services, setServices }) {
  const [serviceName, setServiceName] = useState(service.name || '')
  const [serviceDescription, setServiceDescription] = useState(
    service.description || ''
  )
  const [servicePreview, setServicePreview] = useState(
    service.preview_url || ''
  )
  const [serviceRate, setServiceRate] = useState(service.rate || 5.0)
  const onPreviewSave = data => {
    setServicePreview(data.url)
  }
  const Uploader = useUploader({ onSave: onPreviewSave })

  const handleSave = async closeModal => {
    if (!serviceName) {
      return notify.error('Service name is required')
    }
    const response = await request.put(`/services/${service.id}`, {
      service: {
        name: serviceName,
        description: serviceDescription,
        preview_url: servicePreview,
        rate: serviceRate,
      },
    })
    setServices(services.map(s => (s.id === service.id ? response.data : s)))
    notify.success('Service updated!')
    closeModal()
  }

  return (
    <Dialog>
      <DialogTrigger is="secondary" iconBefore="edit-2">
        Edit
      </DialogTrigger>
      <DialogContent>
        <DialogHeading>Edit service</DialogHeading>
        <div className="py-4 flex flex-col gap-4">
          <div className="field">
            <Label htmlFor="service_name">Service name</Label>
            <Input
              id="service_name"
              value={serviceName}
              placeholder="Service name"
              onChange={e => setServiceName(e.target.value)}
            />
          </div>
          <div className="field">
            <Label htmlFor="service_preview">Service preview image URL</Label>
            <div className="flex gap-5">
              <Avatar
                src={servicePreview}
                shape="rounded"
                className="object-cover"
              />
              <Uploader.Component />
            </div>
          </div>
          <div className="field">
            <Label htmlFor="service_rate">Rate</Label>
            <Input
              id="service_rate"
              before={<Addon>$</Addon>}
              value={serviceRate}
              inputMode="numeric"
              type="number"
              onChange={e => setServiceRate(e.target.value)}
            />
          </div>
          <div className="field">
            <Label htmlFor="service_description">Description</Label>
            <Input
              id="service_description"
              value={serviceDescription}
              type="textarea"
              placeholder="Service description"
              onChange={e => setServiceDescription(e.target.value)}
            />
          </div>
        </div>
        <DialogActions>
          <DialogClose is="secondary">Cancel</DialogClose>
          <DialogAction is="primary" onClick={handleSave}>
            Save
          </DialogAction>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
