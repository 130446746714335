import React from 'react'
import { Heading, Text } from '../../components/typography'
import { SimpleEmpty } from '../../components/empty'

export default function About({ account }) {
  return (
    <div className="wrapper-sm py-4">
      {account.bio ? (
        <>
          <Heading level={2} size="xl" className="mb-4">
            Description
          </Heading>
          <Text size="lg" className="whitespace-pre-line">
            {account.bio}
          </Text>
        </>
      ) : (
        <div className="h-80">
          <SimpleEmpty>
            <Text className="text-center">There is no bio yet</Text>
          </SimpleEmpty>
        </div>
      )}
    </div>
  )
}
