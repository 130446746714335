import React from 'react'

export default function WidgetHeader({ title, children }) {
  return (
    <div className="flex justify-between items-center">
      <div className="font-semibold text-lg">{title}</div>
      <div className="text-brown-600 text-sm">{children}</div>
    </div>
  )
}
