import React from 'react'
import Panel from './components/panel'
import Footer from './components/footer'
import cx from 'classnames'

function SettingsNav({ url, currentUser }) {
  const stylistLinks = [
    { label: 'Business profile', href: '/settings/profile' },
    { label: 'Services', href: '/settings/services' },
    { label: 'Payments', href: '/settings/payments' },
    { label: 'Availability', href: '/settings/availability' },
  ]
  const clientLinks = [{ label: 'Billing', href: '/settings/billing' }]
  const roleLinks = currentUser.role === 'stylist' ? stylistLinks : clientLinks
  const links = roleLinks.concat({
    label: 'Account Settings',
    href: '/settings/account',
  })

  const isCurrent = href => url === href
  return (
    <div className="flex gap-4 flex-wrap">
      {links.map(link => (
        <a
          key={link.href}
          href={link.href}
          className={cx('p-4 rounded-lg shadow transition-colors duration-75', {
            'bg-white hover:bg-gray-100': !isCurrent(link.href),
            'bg-brown-200 hover:bg-brown-300 font-medium': isCurrent(link.href),
          })}
          aria-current={isCurrent(link.href)}
        >
          {link.label}
        </a>
      ))}
    </div>
  )
}

export default function SettingsLayout({ children, ...props }) {
  const pageClassName = cx('', {
    'wrapper flex flex-col lg:flex-row gap-6 pt-8': !props.hidePanel,
  })
  return (
    <>
      <div className={pageClassName}>
        {!props.hidePanel && <Panel {...props} />}
        <div className="flex-1 flex flex-col gap-4">
          <SettingsNav {...props} />
          <div className="bg-white shadow rounded-lg p-8">{children}</div>
        </div>
      </div>
      {!props.hideFooter && <Footer {...props} />}
    </>
  )
}
