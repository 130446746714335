import React from 'react'
import Panel from './components/panel'
import Footer from './components/footer'
import cx from 'classnames'

export default function MainLayout({ children, ...props }) {
  const pageClassName = cx('', {
    'wrapper flex flex-col lg:flex-row gap-6 pt-8': !props.hidePanel,
  })
  return (
    <>
      <div className={pageClassName}>
        {!props.hidePanel && <Panel {...props} />}
        {children}
      </div>
      {!props.hideFooter && <Footer {...props} />}
    </>
  )
}
