import React from 'react'
import Button from '../../../components/button'
import Icon from '../../../components/icon'
import WidgetHeader from '../widget-header'
import Avatar from '../../../components/avatar'
import { SimpleEmpty } from '../../../components/empty'
import { Text } from '../../../components/typography'

const Client = ({ name, email, totalSpent, image }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-4 items-center">
        <Avatar src={image} alt={`customer ${name}`} size="xs" />
        <div className="flex flex-col">
          <div className="font-medium">{name}</div>
          <div className="text-sm text-green-500">
            ${(totalSpent || 0).toFixed(2)}
          </div>
        </div>
      </div>
      <div>
        <Button
          title="Send a message"
          className="grid place-content-center bg-gray-100 hover:bg-gray-200 w-10 h-10 rounded-full"
          href={`mailto:${email}`}
        >
          <Icon name="mail" className="w-5 h-5 text-gray-800" />
        </Button>
      </div>
    </div>
  )
}

export default function StylistClients({ clients }) {
  const noClients = clients.length <= 0
  return (
    <div className="bg-white shadow rounded-lg p-4 flex flex-col gap-5">
      <WidgetHeader title="Top clients">
        <a href="/clients">View all clients</a>
      </WidgetHeader>

      {noClients ? (
        <SimpleEmpty>
          <Text>No clients to display yet</Text>
        </SimpleEmpty>
      ) : (
        <div className="flex flex-col gap-4">
          {clients.map(client => (
            <Client
              key={client.id}
              name={client.name || client.email}
              email={client.email}
              image={client.avatar}
              totalSpent={client.payments}
            />
          ))}
        </div>
      )}
    </div>
  )
}
