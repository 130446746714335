import React, { useState, useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './checkout-form'
import { request } from '../../../utils/request'

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)

export default function Pay({ serviceId, metadata, clientEmail }) {
  const [clientSecret, setClientSecret] = useState(null)
  const [options, setOptions] = useState(null)

  const createPaymentIntent = async () => {
    const response = await request.post(`/stripe/payment/${serviceId}`, {
      metadata,
    })
    setClientSecret(response.data.client_secret)
    setOptions({
      mode: 'payment',
      amount: response.data.amount,
      currency: response.data.currency,
      appearance: {
        theme: 'stripe',
      },
    })
  }

  useEffect(() => {
    if (clientSecret) return

    createPaymentIntent()
  }, [serviceId, clientSecret])

  if (!options) return null

  return (
    <div className="my-8">
      <div className="wrapper-sm">
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            serviceId={serviceId}
            clientSecret={clientSecret}
            clientEmail={clientEmail}
          />
        </Elements>
      </div>
    </div>
  )
}
