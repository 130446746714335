import React from 'react'
import Empty from '../../components/empty'

export default function Portfolio({ account }) {
  if (account.portfolio < 1) {
    return <Empty title="No portfolio entries added yet" />
  }
  return (
    <div className="flex flex-wrap text-center">
      {account.portfolio.map((image, index) => (
        <div
          className="w-full md:w-1/2 lg:w-1/3 p-4"
          key={`${image.preview_url + index}`}
        >
          <img
            src={image.preview_url}
            className="aspect-1 object-cover object-center"
          />
        </div>
      ))}
    </div>
  )
}
