import React from 'react'
import WidgetHeader from '../widget-header'

const QuickLink = ({ title, image, href }) => {
  return (
    <div className="bg-gray-100 p-4 rounded-lg flex flex-col gap-2">
      {title}
    </div>
  )
}

export default function QuickLinks() {
  return (
    <div className="bg-white shadow rounded-lg p-4 flex flex-col gap-4">
      <WidgetHeader title="" />
      <div className="grid grid-cols-2 gap-4">
        <QuickLink title="Fade" image="3,000" href="/" />
        <QuickLink title="Locs" value="8,000" href="/" />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <QuickLink title="Braid" image="32,000" href="/" />
        <QuickLink title="Kids" image="49,000" href="/" />
      </div>
    </div>
  )
}
