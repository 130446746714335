const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  content: [
    './public/*.html',
    './app/helpers/**/*.rb',
    './app/javascript/**/*.js',
    './app/views/**/*.{erb,haml,html,slim}',
  ],
  theme: {
    extend: {
      translate: {
        toggle: 'calc(100% - 8px)',
      },
      animation: {
        growPulse: 'growPulse .75s cubic-bezier(0.4, 0, 0.6, 1) infinite',
      },
      keyframes: {
        growPulse: {
          '0%, 100%': { transform: 'scale(0.85)', opacity: 0.2 },
          '50%': { transform: 'scale(1)', opacity: 1 },
        },
      },
    },
    colors: {
      gold: '#D9B081',
      white: '#FFFFFF',
      black: '#000000',
      transparent: 'transparent',
      translucent_dark: 'rgb(0 0 0 / 0.10)',
      translucent_light: 'rgb(255 255 255 / 0.10)',
      gray: {
        100: '#F7F7F7',
        200: '#E5E5E5',
        300: '#D4D4D4',
        400: '#A3A3A3',
        500: '#737373',
        600: '#4A4A4A',
        700: '#3A3A3A',
        800: '#2C2C2C',
        900: '#1D1D1D',
      },
      brown: {
        100: '#F4F3ED',
        200: '#E3CAAF',
        300: '#D9B081',
        400: '#D2A36C',
        500: '#F0AA78',
        600: '#C98E4A',
        700: '#8B6548',
        800: '#69503A',
        900: '#181203',
      },
      green: {
        100: '#F0F9F0',
        200: '#D9EED9',
        300: '#C2E3C2',
        400: '#94CE94',
        500: '#66B966',
        600: '#5CA65C',
        700: '#3E5F5D',
        800: '#2F532F',
        900: '#1F371F',
      },
      red: {
        100: '#FEDFE1',
        200: '#FDBFC3',
        300: '#FDA0A4',
        400: '#FC8086',
        500: '#FB6068',
        600: '#D94D54',
        700: '#B63A40',
        800: '#94372E',
        900: '#72251B',
      },
      blue: {
        500: '#011627',
      },
      yellow: {
        50: '#FFF9E9',
        100: '#FFF3D2',
        200: '#FFEAB3',
        300: '#FFE194',
        400: '#FFD775',
        500: '#FFCD56',
        600: '#E6B84D',
        700: '#B38C3A',
        800: '#BE8D00',
        900: '#8C6600',
      },
    },
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/typography'),
  ],
}
