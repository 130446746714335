import React from 'react'
import { Text } from './typography'

export default function UnderConstruction({ title, children }) {
  return (
    <div className="grid place-content-center bg-white flex-1 py-40 px-8 rounded-lg shadow">
      <div className="flex flex-col items-center gap-4 text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          fillRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="2"
          clipRule="evenodd"
          viewBox="0 0 100 100"
          className="w-20 text-brown-600"
        >
          <path
            fillRule="nonzero"
            d="M86.646 49.602h10.687a2 2 0 0 0 2-2V9.806c0-.93-.638-1.704-1.497-1.928-.022-.007-.042-.02-.064-.025a1.98 1.98 0 0 0-.846-.046H2.667a2 2 0 0 0-2 2v37.796a2 2 0 0 0 2 2h12.084v18.96H2.667a2 2 0 0 0-2 2v13.07a2 2 0 0 0 2 2h12.084v14.72h3.999v-14.72h62.497v14.72h3.999v-14.72h12.085a2 2 0 0 0 2-2v-13.07a2 2 0 0 0-2-2H85.248v-18.96h1.398v-.001Zm-13.976-4 9.456-33.796h12.463l-9.456 33.796H72.67Zm-16.614 0 9.455-33.796h12.463l-9.456 33.796H56.056Zm-16.615 0 9.456-33.796h12.46l-9.455 33.796H39.441Zm-16.613 0 9.456-33.796h12.459l-9.456 33.796H22.828Zm-16.615 0 9.456-33.796H28.13l-9.456 33.796H6.213Zm83.072 0 6.048-21.613v21.613h-6.048ZM11.516 11.806 4.667 36.283V11.806h6.849Zm83.817 69.827H4.667v-9.07h90.666v9.07ZM81.248 68.562H18.751v-18.96h62.497v18.96Z"
          />
        </svg>
        <Text size="lg" className="font-semibold text-gray-600">
          {title || 'Under construction'}
        </Text>
        {children}
      </div>
    </div>
  )
}
