/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)

import React, { Suspense } from 'react'
import * as Sentry from '@sentry/react'
import { createInertiaApp } from '@inertiajs/react'
import { createRoot } from 'react-dom/client'
import PageLoading from './components/loading'
import ErrorBoundaryFallback from './errors/fallback'

Sentry.init({
  dsn: process.env.SENTRY_REACT_DSN,
  tunnel: '/tunnel/sentry',
  environment: process.env.NODE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost:8000', 'https://app.bookstyleq.com'],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0, // This sets the sample rate at 20% in production and 100% in development.
  // Session Replay
  replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
  replaysOnErrorSampleRate: 1.0,
})

document.addEventListener('DOMContentLoaded', () => {
  createInertiaApp({
    resolve: name => require(`./${name}`),
    setup: ({ el, App, props }) => {
      const csrf = document.querySelector('meta[name=csrf-token]')
      const csrfToken = csrf ? csrf.content : null
      const pageProps = props.initialPage.props
      const url = props.initialPage.url
      const layout = pageProps.layout || 'main'

      const LayoutComponent = React.lazy(() => import(`./layouts/${layout}`))
      const newProps = { ...pageProps, url, layout, csrfToken }
      props.initialPage.props = newProps

      createRoot(el).render(
        <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
          <Suspense fallback={<PageLoading />}>
            <LayoutComponent {...newProps}>
              <App {...props} />
            </LayoutComponent>
          </Suspense>
        </Sentry.ErrorBoundary>
      )
    },
  })
})

const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        '/service-worker.js',
        {
          scope: '/',
        }
      )
      if (registration.installing) {
        console.log('Service worker installing')
      } else if (registration.waiting) {
        console.log('Service worker installed')
      } else if (registration.active) {
        console.log('Service worker active')
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`)
    }
  }
}

registerServiceWorker()
