import React from 'react'
import cx from 'classnames'
import { hasTailwindPrefix } from '../utils/tailwind'

const sizes = {
  xs: { width: 40, height: 40, sizeClass: 'w-10 h-10' },
  sm: { width: 48, height: 48, sizeClass: 'w-12 h-12' },
  md: { width: 64, height: 64, sizeClass: 'w-16 h-16' },
  lg: { width: 96, height: 96, sizeClass: 'w-24 h-24' },
  xl: { width: 128, height: 128, sizeClass: 'w-32 h-32' },
  '2xl': { width: 192, height: 192, sizeClass: 'w-48 h-48' },
  '3xl': { width: 256, height: 256, sizeClass: 'w-64 h-64' },
  '4xl': { width: 384, height: 384, sizeClass: 'w-96 h-96' },
}

const shapes = {
  circle: 'rounded-full',
  square: 'rounded-md',
  rounded: 'rounded-lg',
}

/**
 * Avatar is a square or circular image that represents a user.
 * It does not support a height prop because it is always a square.
 * @typedef {Object} AvatarProps
 * @property {string} src
 * @property {string} [size]
 * @property {number} [width]
 * @property {string} [shape]
 * @property {string} [className]
 * @property {string} [alt]
 * @property {string} [priority]
 * @property {string} [decoding]
 * @property {React.HTMLProps<HTMLImageElement>} [props]
 * @param {AvatarProps} props
 * @returns {JSX.Element}
 *
 * @example
 * <Avatar src="https://picsum.photos/200" size="md" />
 */
export default function Avatar({
  src,
  size = 'md',
  width,
  shape = 'circle',
  className,
  alt = 'profile image',
  priority = 'high',
  decoding = 'async',
  ...props
}) {
  const source = src
  const generatedSize = sizes[size]
  const finalWidth = width || generatedSize.width
  const finalHeight = width || generatedSize.height
  const sizeClass = width ? '' : generatedSize.sizeClass
  return (
    <img
      {...props}
      src={source}
      alt={alt}
      width={finalWidth}
      height={finalHeight}
      fetchpriority={priority}
      decoding={decoding}
      className={cx(
        'bg-gray-100',
        {
          [sizeClass]: !hasTailwindPrefix('w-', className),
          [shapes[shape]]: !hasTailwindPrefix('rounded', className),
          'object-contain': !hasTailwindPrefix('object', className),
          'border border-gray-200': !hasTailwindPrefix('border', className),
        },
        className
      )}
    />
  )
}
