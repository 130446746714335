import React, { useState, useCallback, useEffect } from 'react'
import cx from 'classnames'
import { useDropzone } from 'react-dropzone'
import { Text } from './typography'
import Icon from './icon'
import { request } from '../utils/request'

const uploaderCaptions = (status, caption) =>
  ({
    idle: () => (
      <Text>
        <strong>Click to upload</strong> or drag and drop an image (max. 5mb)
      </Text>
    ),
    loading: () => <Text>Uploading...</Text>,
    error: () => <Text>{caption}</Text>,
  }[status])

export default function Uploader({
  onUpload,
  status = 'idle',
  error = 'Something went wrong',
}) {
  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0]
    processFile(file, onUpload)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div
      className={cx(
        { 'bg-gray-100': isDragActive },
        'w-80 h-36 border border-gray-200 hover:bg-gray-100 rounded-md py-4 px-8 text-center max-w-xs flex flex-col justify-center items-center cursor-pointer'
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Icon name="upload-cloud" className="mb-2 w-8 h-8" />
      {isDragActive ? (
        <Text>Drop the files here ...</Text>
      ) : (
        uploaderCaptions(status, error)()
      )}
    </div>
  )
}

function processFile(file, onUpload) {
  const reader = new FileReader()
  if (onUpload) {
    reader.onload = () => {
      onUpload({
        file,
        dataURL: reader.result,
      })
    }
  }
  if (file) {
    reader.readAsDataURL(file)
  }
}

export async function serverUpload(file) {
  var formData = new FormData()
  formData.append('file', file)
  try {
    const response = await request.post('/upload', formData, {
      onUploadProgress: ({ progress }) => {
        console.log(progress)
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  } catch (error) {
    return error.response
  }
}

export function useUploader({ onSave, saveToServer = true }) {
  const [file, setFile] = useState(null)
  const [dataURL, setDataURL] = useState(null)
  const [status, setStatus] = useState('idle')
  const [error, setError] = useState(null)

  const handleUpload = async ({ file, dataURL }) => {
    setStatus('loading')
    setFile(file)
    setDataURL(dataURL)
    if (saveToServer) {
      const res = await serverUpload(file)
      if (onSave) {
        if (res.status === 200) {
          setStatus('idle')
          onSave(res.data)
        } else {
          setStatus('error')
          setError(res.data.message)
        }
      }
    }
  }

  const Component = () => (
    <Uploader onUpload={handleUpload} status={status} error={error} />
  )

  return {
    file,
    dataURL,
    status,
    Component,
  }
}
