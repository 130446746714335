import React, {
  createContext,
  forwardRef,
  useContext,
  useState,
  useLayoutEffect,
} from 'react'
import cx from 'classnames'
import Button from './button'

const StepsContext = createContext()

/**
 * Steps components
 * @example ```
 * <Steps>
 *  <StepList>
 *   <StepItem number={1}>Nav Item 1</StepItem>
 *   <StepItem number={2}>Nav Item 2</StepItem>
 *   <StepItem number={3}>Nav Item 1</StepItem>
 *  </StepList>
 *  <Step number={1}>
 *   <div>Item 1 content</div>
 *  </Step>
 *  <Step number={2}>
 *   <div>Item 2 content</div>
 *  </Step>
 *  <Step number={3}>
 *   <div>Item 3 content</div>
 *  </Step>
 * </Steps>
 * ```
 */
export function Steps({
  children,
  initialStep = 1,
  is = 'progress',
  disableNextSteps = false,
  className,
  ...props
}) {
  const [currentStep, setCurrentStep] = useState(initialStep)
  const [totalSteps, setTotalSteps] = useState(1)
  const [theme, setTheme] = useState(is)

  return (
    <StepsContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        totalSteps,
        setTotalSteps,
        theme,
        setTheme,
        disableNextSteps,
      }}
    >
      <div className={className} {...props}>
        {children}
      </div>
    </StepsContext.Provider>
  )
}

export function useStepsContext() {
  const context = useContext(StepsContext)
  if (!context) {
    throw new Error(
      `Steps compound components cannot be rendered outside the Steps component`
    )
  }

  return context
}

export function Step({ children, number }) {
  const { currentStep } = useStepsContext()

  if (number === currentStep) {
    return children
  }

  return null
}

export function StepList({ children, className }) {
  const { setTotalSteps, theme } = useStepsContext()
  const classes =
    cx(
      {
        'flex w-full': theme === 'progress',
      },
      className
    ) || null

  useLayoutEffect(() => {
    setTotalSteps(React.Children.count(children))
  }, [children, setTotalSteps])

  return <div className={classes}>{children}</div>
}

export const StepItem = forwardRef(function StepItem(
  { children, number, className },
  ref
) {
  const { currentStep, setCurrentStep, theme, totalSteps, disableNextSteps } =
    useStepsContext()
  const disabled = disableNextSteps ? currentStep < number : false
  const classes = cx(
    'font-semibold',
    {
      'bg-green-500 hover:bg-green-600 text-white':
        !disabled && theme === 'progress',
      'bg-gray-100 text-gray-800': disabled && theme === 'progress',
    },
    className
  )
  const width = theme === 'progress' ? `${100 / totalSteps}%` : null

  return (
    <Button
      className={classes}
      style={{ width }}
      disabled={disabled}
      onClick={() => setCurrentStep(number)}
      ref={ref}
    >
      {children}
    </Button>
  )
})

export const NextButton = forwardRef(function NextButton(
  { children, onClick, ...props },
  ref
) {
  const { currentStep, setCurrentStep, totalSteps } = useStepsContext()
  const handleClick = () => {
    if (onClick) {
      const clickReturn = onClick()
      if (clickReturn === false) return
    }
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1)
    }
  }
  return (
    <Button onClick={handleClick} {...props} ref={ref}>
      {children}
    </Button>
  )
})

export const PreviousButton = forwardRef(function PreviousButton(
  { children, onClick, ...props },
  ref
) {
  const { currentStep, setCurrentStep } = useStepsContext()
  const handleClick = () => {
    if (onClick) {
      const clickReturn = onClick()
      if (clickReturn === false) return
    }
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1)
    }
  }
  return (
    <Button onClick={handleClick} {...props} ref={ref}>
      {children}
    </Button>
  )
})
