import React from 'react'
import cx from 'classnames'

export default function SelectMenu({
  defaultOption = 'Select an option',
  options,
  className,
  onChange,
  ...props
}) {
  const handleChange = event => {
    if (onChange) onChange(event.target.value)
  }

  const classes = cx('border border-gray-300 rounded-md w-full', className)

  return (
    <select
      {...props}
      className={classes}
      onChange={handleChange}
      value={options.find(option => option.selected)?.value}
    >
      <option
        value=""
        disabled
        selected={!options.find(option => option.selected)}
      >
        {defaultOption}
      </option>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  )
}
