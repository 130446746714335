import React from 'react'
import cx from 'classnames'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { hasTailwindPrefix } from '../utils/tailwind'

export default function DatePicker({
  showOutsideDays = true,
  fixedWeeks = true,
  mode = 'single',
  numberOfMonths = 1,
  className,
  ...props
}) {
  const classes = cx(
    'p-4 md:p-8',
    {
      'border border-gray-100': !hasTailwindPrefix('border', className),
      rounded: !hasTailwindPrefix('rounded', className),
      'shadow-sm': !hasTailwindPrefix('shadow', className),
      'bg-white': !hasTailwindPrefix('bg', className),
    },
    className
  )

  return (
    <div className={classes}>
      <DayPicker
        showOutsideDays={showOutsideDays}
        fixedWeeks={fixedWeeks}
        mode={mode}
        numberOfMonths={numberOfMonths}
        {...props}
      />
    </div>
  )
}
