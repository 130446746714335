import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react'
import React, {
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import cx from 'classnames'
import Button from './button'
import { Heading } from './typography'

export const DialogContext = createContext()

export const useDialogContext = () => {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error(
      `Dialog compound components cannot be rendered outside the Dialog component`
    )
  }
  return context
}

export const useDialog = ({
  initialOpen = false,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)
  const [labelId, setLabelId] = useState()
  const [descriptionId, setDescriptionId] = useState()

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    open,
    onOpenChange: setOpen,
  })

  const context = data.context

  const click = useClick(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
  })
  const role = useRole(context)

  const interactions = useInteractions([click, dismiss, role])

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, interactions, data, labelId, descriptionId]
  )
}

export const Dialog = ({ children, ...options }) => {
  const dialog = useDialog(options)
  return (
    <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
  )
}

export const DialogTrigger = forwardRef(function DialogTrigger(
  { children, asChild = false, ...props },
  propRef
) {
  const context = useDialogContext()
  const childrenRef = children.ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  // asChild allows the user to pass any element as the anchor
  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      })
    )
  }

  return (
    <Button
      ref={ref}
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </Button>
  )
})

export const DialogContent = forwardRef(function DialogContent(props, propRef) {
  const { context: floatingContext, ...context } = useDialogContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])
  const size = props.size ?? 'md'
  const contentClasses = cx(
    `bg-white p-4 rounded-md shadow-md w-10/12 max-w-${size}`,
    props.className
  )

  if (!floatingContext.open) return null

  return (
    <FloatingPortal>
      <FloatingOverlay
        lockScroll
        className="backdrop-blur-sm bg-[#000000be] flex items-center justify-center"
      >
        <FloatingFocusManager context={floatingContext}>
          <div
            ref={ref}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            {...context.getFloatingProps(props)}
            className={contentClasses}
          >
            {props.children}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  )
})

export const DialogHeading = forwardRef(function DialogHeading(
  { children, className, ...props },
  ref
) {
  const { setLabelId } = useDialogContext()
  const id = useId()

  // Only sets `aria-labelledby` on the Dialog root element
  // if this component is mounted inside it
  useLayoutEffect(() => {
    setLabelId(id)
    return () => setLabelId(undefined)
  }, [id, setLabelId])

  return (
    <Heading
      level={2}
      {...props}
      ref={ref}
      id={id}
      className={cx('border-b border-gray-200 pb-3 text-lg', className)}
    >
      {children}
    </Heading>
  )
})

export const DialogDescription = forwardRef(function DialogDescription(
  { children, ...props },
  ref
) {
  const { setDescriptionId } = useDialogContext()
  const id = useId()

  // Only sets `aria-describedby` on the Dialog root element
  // if this component is mounted inside it
  useLayoutEffect(() => {
    setDescriptionId(id)
    return () => setDescriptionId(undefined)
  }, [id, setDescriptionId])

  return (
    <div {...props} ref={ref} id={id}>
      {children}
    </div>
  )
})

export const DialogClose = forwardRef(function DialogClose(props, ref) {
  const { setOpen } = useDialogContext()
  return <Button {...props} ref={ref} onClick={() => setOpen(false)} />
})

export const DialogAction = forwardRef(function DialogActions(
  { onClick, ...props },
  ref
) {
  const { setOpen } = useDialogContext()
  const handleClick = event => {
    if (onClick) onClick(() => setOpen(false), event)
    else setOpen(false)
  }

  return <Button {...props} ref={ref} onClick={handleClick} />
})

export const DialogActions = forwardRef(function DialogActions(
  { children, className, ...props },
  ref
) {
  return (
    <div
      {...props}
      ref={ref}
      className={cx(
        'flex justify-end gap-2 pt-3 border-t border-gray-200',
        className
      )}
    >
      {children}
    </div>
  )
})

export const ConfirmDialog = ({
  onConfirm,
  caption = 'Are you sure?',
  children,
  triggerButtonContent,
  ...props
}) => {
  const handleConfirm = closeModal => {
    if (onConfirm) onConfirm(closeModal)
  }

  return (
    <Dialog>
      <DialogTrigger {...props}>{triggerButtonContent}</DialogTrigger>
      <DialogContent>
        <div className="py-8">
          <div className="text-center font-semibold text-lg">{caption}</div>
          {children && <div className="pt-4">{children}</div>}
        </div>
        <DialogActions>
          <DialogClose is="secondary">Cancel</DialogClose>
          <DialogAction is="primary" onClick={handleConfirm}>
            Confirm
          </DialogAction>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
