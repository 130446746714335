import React from 'react'
import ClientCalendarWidget from './widgets/client-calendar'
import ClientStylists from './widgets/client-stylists'
import QuickLinks from './widgets/client-quicklinks'

export default function ClientWidgets() {
  return (
    <div className="flex flex-col gap-4">
      <div className="grid lg:grid-cols-2 gap-4">
        <QuickLinks />
        <ClientStylists />
      </div>
      <ClientCalendarWidget />
    </div>
  )
}
