import React, { useState, useReducer } from 'react'
import Input, { Addon } from '../components/input'
import Label from '../components/label'
import { Text, Heading } from '../components/typography'
import { useUploader } from '../components/uploader'
import Icon from '../components/icon'
import SettingGroup from './components/setting-group'
import Avatar from '../components/avatar'
import { request } from '../utils/request'
import { useDebouncedCallback } from 'use-debounce'
import notify from '../components/notify'
import PortfolioImage from './components/portfolio-image'
import { Tooltip, TooltipTrigger, TooltipContent } from '../components/tooltip'
import Checkbox from '../components/checkbox'

const byEnvSlug = (slug, accountId, env) => {
  const url =
    env === 'development'
      ? `${process.env.APP_DOMAIN}/p/${slug || accountId}`
      : `${slug || accountId}.styleq.page`
  const inputAddon = {
    before:
      env === 'development' ? <Addon>{process.env.APP_DOMAIN}/p/</Addon> : null,
    after: env === 'development' ? null : <Addon>.styleq.page</Addon>,
  }
  return {
    url,
    inputAddon,
  }
}

export default function ProfileSettings({ currentUser, env }) {
  const [state, dispatch] = useReducer(
    (currentState, newState) => ({ ...currentState, ...newState }),
    {
      updates: 0,
      name: currentUser.account.name || '',
      slug: currentUser.account.slug || '',
      bio: currentUser.account.bio || '',
      logo: currentUser.account.logo || '',
      rating: currentUser.account.rating || 0,
      phoneNumber: currentUser.account.phone_number || '',
      email: currentUser.account.email || '',
      website: currentUser.account.website_url || '',
      facebook: currentUser.account.facebook_url || '',
      twitter: currentUser.account.twitter_url || '',
      instagram: currentUser.account.instagram_url || '',
      youtube: currentUser.account.youtube_url || '',
      portfolio: currentUser.account.portfolio || [],
    }
  )
  const [isAccountEmail, setIsAccountEmail] = useState(
    currentUser.email === state.email
  )
  const { url: profileURL, inputAddon } = byEnvSlug(
    state.slug,
    currentUser.account.id,
    env
  )
  const onAvatarSave = data => {
    update({ name: 'logo', value: data.url })
  }
  const AvatarUploader = useUploader({ onSave: onAvatarSave })
  const onPortfolioSave = data => {
    handlePortfolioCreate(data.url)
  }
  const PortfolioUploader = useUploader({ onSave: onPortfolioSave })

  function handlePortfolioCreate(preview_url) {
    request
      .post('/portfolio_entries', {
        portfolio: {
          preview_url,
        },
      })
      .then(res => {
        update({
          name: 'portfolio',
          value: [res.data, ...state.portfolio],
        })

        notify.success('Portfolio image added')
      })
  }

  const debounced = useDebouncedCallback(() => {
    request
      .put('/settings/profile', {
        account: {
          name: state.name,
          slug: state.slug || null,
          bio: state.bio,
          phone_number: state.phoneNumber,
          email: state.email,
          logo_url: state.logo,
        },
        links: {
          website: state.website,
          facebook: state.facebook,
          twitter: state.twitter,
          instagram: state.instagram,
          youtube: state.youtube,
        },
      })
      .then(res => {
        const { data } = res
        dispatch({
          name: data.name || state.name,
          slug: data.slug || state.slug,
          bio: data.bio || state.bio,
          phoneNumber: data.phone_number || state.phoneNumber,
          email: data.email || state.email,
          logo: data.logo || state.logo,
          rating: data.rating || state.rating,
          website: data.website_url || state.website,
          facebook: data.facebook_url || state.facebook,
          twitter: data.twitter_url || state.twitter,
          instagram: data.instagram_url || state.instagram,
          youtube: data.youtube_url || state.youtube,
        })
        notify.success('Profile updated')
      })
      .catch(errorObject => {
        const { errors } = errorObject.response?.data || {}
        const error = errors?.length
          ? errors[0]
          : errorObject.message || 'An error occurred'
        notify.error(error)
      })
  }, 2000)

  const handleSubmit = () => {
    debounced()
  }

  const update = (event, isValid) => {
    const { name, value } = event.target || event
    dispatch({ [name]: value })
    if (isValid !== false) {
      handleSubmit()
    }
  }

  const toggleAccountEmail = () => {
    if (!isAccountEmail) {
      update({ name: 'email', value: currentUser.email })
    }
    setIsAccountEmail(!isAccountEmail)
  }

  return (
    <div>
      <Heading level={3} size="2xl" className="font-semibold mb-2">
        Business profile settings
      </Heading>
      <Text className="text-gray-500">
        Update your business profile and details here
      </Text>
      <div className="my-8 flex items-center gap-4">
        <Avatar src={state.logo} size="xl" className="rounded-xl w-32 h-32" />
        <div className="flex flex-col gap-2">
          <Text size="2xl" className="font-medium">
            {state.name}
          </Text>
          <div className="flex gap-1 items-center">
            <Icon
              name="star"
              className="inline-block w-4 h-4"
              fill="currentColor"
            />
            <Text size="xs">{state.rating}</Text>
          </div>
          <a
            className="text-gray-500 break-all"
            href={`${process.env.APP_PROTOCOL}://${profileURL}`}
            rel="noreferrer"
            target="_blank"
          >
            <span className="align-middle">{profileURL}</span>
            <Icon name="external-link" className="inline-block ml-1 w-4 h-4" />
          </a>
        </div>
      </div>
      <div>
        <SettingGroup
          title="Public profile"
          description="Information about your business will be displayed on your profile"
        >
          <div>
            <Label htmlFor="account_name">Business name</Label>
            <Input
              placeholder="e.g Cool Cuts LLC"
              value={state.name}
              id="account_name"
              name="name"
              onChange={update}
            />
          </div>
          <div>
            <Label htmlFor="slug">
              Profile Link name{' '}
              <span className="text-gray-500">
                (no spaces or special characters)
              </span>
            </Label>
            <Input
              {...inputAddon}
              placeholder="businessname"
              responsive
              id="slug"
              name="slug"
              value={state.slug}
              minLength={2}
              pattern={Input.patterns.slug}
              onChange={update}
            />
          </div>
          <div>
            <Label htmlFor="bio">Bio</Label>
            <Input
              placeholder="Business description"
              type="textarea"
              value={state.bio}
              id="bio"
              name="bio"
              onChange={update}
            />
          </div>
        </SettingGroup>
        <SettingGroup
          title="Logo"
          description={
            <div>
              Upload your business logo for your brand identity. We will try to
              get your logo with your website link through{' '}
              <a
                href="https://clearbit.com"
                className="text-brown-600 underline"
                target="_blank"
                rel="noreferrer"
              >
                Clearbit{' '}
                <Icon name="external-link" className="inline w-4 h-4" />
              </a>{' '}
              if you don't upload one.
            </div>
          }
        >
          <div className="flex gap-5">
            <Avatar src={state.logo} shape="rounded" />
            <AvatarUploader.Component />
          </div>
        </SettingGroup>
        <SettingGroup
          title="Social profiles"
          description="Add links to your social media profiles and website if available"
        >
          <Input
            placeholder="website.com"
            defaultValue={state.website}
            responsive
            before={
              <Addon>
                <Icon name="globe" />
              </Addon>
            }
            name="website"
            onChange={update}
          />
          <Input
            placeholder="facebook.com/businessname"
            defaultValue={state.facebook}
            responsive
            before={
              <Addon>
                <Icon name="facebook" />
              </Addon>
            }
            pattern={Input.patterns.link}
            name="facebook"
            onChange={update}
          />
          <Input
            placeholder="instagram.com/businessname"
            defaultValue={state.instagram}
            responsive
            before={
              <Addon>
                <Icon name="instagram" />
              </Addon>
            }
            pattern={Input.patterns.link}
            name="instagram"
            onChange={update}
          />
          <Input
            placeholder="twitter.com/businessname"
            defaultValue={state.twitter}
            responsive
            before={
              <Addon>
                <Icon name="twitter" />
              </Addon>
            }
            pattern={Input.patterns.link}
            name="twitter"
            onChange={update}
          />
          <Input
            placeholder="youtube.com/user/businessname"
            defaultValue={state.youtube}
            responsive
            before={
              <Addon>
                <Icon name="youtube" />
              </Addon>
            }
            pattern={Input.patterns.link}
            name="youtube"
            onChange={update}
          />
        </SettingGroup>
        <SettingGroup
          title="Contact details"
          description="Publicly shared contact details to help your clients reach you"
        >
          <div>
            <Label htmlFor="phone">Phone number</Label>
            <Input
              placeholder="e.g 234-567-8900"
              value={state.phoneNumber}
              type="tel"
              name="phoneNumber"
              pattern={Input.patterns.phone}
              onChange={update}
            />
          </div>
          <div>
            <Label htmlFor="email">Business email</Label>
            <div className="flex gap-2 items-center">
              <Checkbox
                id="isAccountEmail"
                name="isAccountEmail"
                checked={!isAccountEmail}
                onChange={toggleAccountEmail}
              />
              <Label plain htmlFor="isAccountEmail">
                Use a different email address for your business
              </Label>
              <Tooltip>
                <TooltipTrigger>
                  <Icon name="info" className="w-4 h-4" />
                </TooltipTrigger>
                <TooltipContent>
                  If you would like to use a separate email address to handle
                  payments and other business transactions, enter your business
                  email address here. Otherwise, we will use your account email
                  address.
                </TooltipContent>
              </Tooltip>
            </div>
            {!isAccountEmail && (
              <Input
                placeholder="name@organization.com"
                value={state.email}
                type="email"
                name="email"
                className="mt-2"
                onChange={update}
              />
            )}
          </div>
        </SettingGroup>
        <SettingGroup
          title="Portfolio"
          description="Showcase your work to potential clients by adding some pictures"
        >
          <PortfolioUploader.Component />
          <div className="md:flex grid grid-cols-3 gap-4 flex-wrap">
            {state.portfolio.map(entry => (
              <PortfolioImage
                key={entry.id}
                entry={entry}
                className="rounded-md w-full md:w-28 md:h-28 object-cover object-center"
                dispatch={dispatch}
                portfolio={state.portfolio}
              />
            ))}
          </div>
        </SettingGroup>
      </div>
    </div>
  )
}
