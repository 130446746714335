import React, { useState } from 'react'
import Avatar from '../components/avatar'
import DatePicker from '../components/datepicker'
import { Heading } from '../components/typography'
import SelectMenu from '../components/select-menu'
import Label from '../components/label'
import Button from '../components/button'
import Input from '../components/input'
import { Text } from '../components/typography'
import {
  StepList,
  Steps,
  StepItem,
  Step,
  NextButton,
  PreviousButton,
} from '../components/steps'
import Pay from './components/pay'
import { getCurrencySymbol } from '../utils/numbers'
import notify from '../components/notify'

export default function Book({ account }) {
  const [serviceId, setServiceId] = useState(null)
  const [date, setDate] = useState(null)
  const [time, setTime] = useState(null)
  const [preferredLocation, setPreferredLocation] = useState('')
  const [address, setAddress] = useState('')
  const [clientEmail, setClientEmail] = useState('')
  const [clientPhone, setClientPhone] = useState('')
  const [clientNotes, setClientNotes] = useState('')
  const [metadata, setMetadata] = useState(null)
  const availableTimes = [
    { label: '5:00 AM', value: 5 },
    { label: '6:00 AM', value: 6 },
    { label: '7:00 AM', value: 7 },
    { label: '8:00 AM', value: 8 },
    { label: '9:00 AM', value: 9 },
    { label: '10:00 AM', value: 10 },
    { label: '11:00 AM', value: 11 },
    { label: '12:00 PM', value: 12 },
    { label: '1:00 PM', value: 13 },
    { label: '2:00 PM', value: 14 },
    { label: '3:00 PM', value: 15 },
    { label: '4:00 PM', value: 16 },
    { label: '5:00 PM', value: 17 },
    { label: '6:00 PM', value: 18 },
    { label: '7:00 PM', value: 19 },
    { label: '8:00 PM', value: 20 },
    { label: '9:00 PM', value: 21 },
    { label: '10:00 PM', value: 22 },
    { label: '11:00 PM', value: 23 },
    { label: '12:00 AM', value: 0 },
  ]

  const onPayClick = () => {
    if (!metadata) {
      setMetadata({
        serviceId,
        starts_at: new Date(date.setHours(time)).toISOString(),
        location:
          preferredLocation !== 'stylist-place' ? address : preferredLocation,
        client_phone: clientPhone,
        notes: clientNotes,
      })
    }
    if (!clientEmail) {
      notify.error('Please enter your email address')
      return false
    }
  }

  return (
    <div className="wrapper-sm my-10 md:my-24">
      <div className="mb-4">
        <Button href={account.profile_url} is="secondary">
          Back to {account.name || 'stylist'}&apos;s page
        </Button>
      </div>
      <div className="bg-white shadow-sm rounded p-4 md:p-8">
        <div className="flex flex-col items-center gap-6">
          <Avatar
            src={account.logo || 'https://picsum.photos/seed/pup/128'}
            size="xl"
            shape="rounded"
          />
          <Heading
            level={1}
            size="3xl"
            className="font-semibold text-brown-900"
          >
            Book an appointment with {account.name || 'StyleQ Stylist'}
          </Heading>
        </div>
        <Steps className="mt-8" disableNextSteps={true}>
          <StepList>
            <StepItem number={1} className="p-4">
              Time
            </StepItem>
            <StepItem number={2} className="p-4">
              Preferences
            </StepItem>
            <StepItem number={3} className="p-4">
              Pay
            </StepItem>
          </StepList>
          <Step number={1}>
            <div className="my-8 mx-auto max-w-md flex flex-col gap-8">
              <div>
                <Label className="text-base">Select service</Label>
                <SelectMenu
                  defaultOption="Select a service"
                  options={account.services.map(service => ({
                    label: `${service.name} - ${getCurrencySymbol(
                      service.currency
                    )}${service.rate}`,
                    value: service.id,
                    selected: service.id === serviceId,
                  }))}
                  onChange={value => setServiceId(value)}
                />
              </div>
              <div>
                <Label className="text-base">Choose a date</Label>
                <DatePicker
                  fromDate={new Date()}
                  disabled={!serviceId}
                  selected={date}
                  onSelect={setDate}
                />
              </div>
              {date && (
                <div>
                  <Label className="text-base">Choose a time</Label>
                  <div className="grid sm:grid-cols-3 gap-2">
                    {availableTimes.map(availableTime => (
                      <Button
                        key={availableTime.value}
                        is={
                          availableTime.value == time ? 'primary' : 'secondary'
                        }
                        onClick={() => setTime(availableTime.value)}
                      >
                        {availableTime.label}
                      </Button>
                    ))}
                  </div>
                </div>
              )}
              <div>
                <NextButton
                  is="primary"
                  disabled={!date || !time}
                  className="w-full"
                >
                  Set location
                </NextButton>
              </div>
            </div>
          </Step>
          <Step number={2}>
            <div className="my-8 mx-auto max-w-md flex flex-col gap-6">
              <div>
                <Label className="text-base">
                  Where will you like to receive this service
                </Label>
                <SelectMenu
                  defaultOption="Select preferred location"
                  options={[
                    {
                      label: 'House (Single family, condo, townhouse, etc.)',
                      value: 'my-house',
                      selected: preferredLocation === 'my-house',
                    },
                    {
                      label: 'Apartment',
                      value: 'my-apartment',
                      selected: preferredLocation === 'my-apartment',
                    },
                    {
                      label: 'Office',
                      value: 'my-office',
                      selected: preferredLocation === 'my-office',
                    },
                    {
                      label: 'Salon/Barbershop',
                      value: 'stylist-place',
                      selected: preferredLocation === 'stylist-place',
                    },
                  ]}
                  onChange={value => setPreferredLocation(value)}
                />
              </div>
              {preferredLocation !== 'stylist-place' && (
                <div>
                  <Label className="text-base">Enter your address</Label>
                  <Input
                    placeholder="123, Brady Str, Milwaukee, WI"
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                  />
                </div>
              )}
              <div>
                <div className="font-semibold text-lg text-brown-900">
                  Get informed about the status of your appointment
                </div>
              </div>
              <div>
                <Label className="text-base">Email address to notify</Label>
                <Input
                  type="email"
                  placeholder="jillgetsacut@provider.com"
                  value={clientEmail}
                  onChange={e => setClientEmail(e.target.value)}
                />
              </div>
              <div>
                <Label className="text-base">
                  Phone number to notify (optional)
                </Label>
                <Input
                  type="tel"
                  placeholder="234-567-8901"
                  pattern={Input.patterns.phone}
                  value={clientPhone}
                  onChange={e => setClientPhone(e.target.value)}
                />
              </div>
              <div>
                <Label className="text-base">Extra notes (optional)</Label>
                <Input
                  type="textarea"
                  placeholder="E.g I have a large dog"
                  value={clientNotes}
                  onChange={e => setClientNotes(e.target.value)}
                />
              </div>
              <div className="flex flex-col gap-2 md:flex-row md:justify-between">
                <PreviousButton is="secondary" className="w-full mr-2">
                  Back
                </PreviousButton>
                <NextButton
                  is="primary"
                  className="w-full mr-2"
                  onClick={onPayClick}
                >
                  Pay
                </NextButton>
              </div>
            </div>
          </Step>
          <Step number={3}>
            <Text className="text-gray-600 mb-2">
              Don’t worry - we won’t charge your card until you confirm your
              booking with the stylist.
            </Text>
            <Pay
              serviceId={serviceId}
              metadata={metadata}
              clientEmail={clientEmail}
            />
          </Step>
        </Steps>
      </div>
    </div>
  )
}
