import React from 'react'
import cx from 'classnames'
import Icon from './icon'

/**
 * StarRating
 * @param {number} rating
 * @param {number} total
 * @returns React.Component
 * @example
 * <StarRating rating={4} total={5} />
 *
 * Note: Avoid using directly inside a flex or grid container.
 * This component uses absolute positioning to render the blend effect
 * and the layout may break if it's width is not constrained.
 */
export default function StarRating({ rating, total = 5, className }) {
  const percentage = Math.round((rating / total) * 100)
  const stars = Array.from({ length: total }).map((_, index) => {
    return (
      <Icon
        key={index}
        name="star"
        className="h-6 w-6 text-yellow-500"
        fill="currentColor"
        stroke="none"
      />
    )
  })
  return (
    <div className={cx('relative inline-flex gap-1 items-center', className)}>
      {stars}
      <div
        className="absolute right-0 inset-y-0 bg-black z-10 mix-blend-color"
        style={{ width: `${100 - percentage}%` }}
      />
    </div>
  )
}
