import React from 'react'
import { Text } from '../components/typography'
import {
  List,
  ListHeader,
  ListItem,
  ListItems,
  ListSection,
} from '../components/list'
import Empty from '../components/empty'
import Avatar from '../components/avatar'

export default function Clients({ clients }) {
  const noClients = clients.length <= 0
  return (
    <div className="flex-1">
      {noClients ? (
        <Empty title="You have no clients yet">
          <Text>
            Your new clients will show up here after setting up appointments
          </Text>
        </Empty>
      ) : (
        <List view="table" columns="80px 1fr 1fr 200px">
          <ListItems>
            <ListHeader>
              <ListSection>
                <span className="sr-only">Avatar</span>
              </ListSection>
              <ListSection>Name</ListSection>
              <ListSection>Email</ListSection>
              <ListSection>Phone</ListSection>
            </ListHeader>
            {clients.map(client => (
              <ListItem key={client.id}>
                <ListSection>
                  <Avatar size="sm" src={client.avatar} />
                </ListSection>
                <ListSection>
                  <Text>{client.name || 'Client'}</Text>
                </ListSection>
                <ListSection>
                  <Text>{client.email}</Text>
                </ListSection>
                <ListSection>
                  <Text>{client.phone_number}</Text>
                </ListSection>
              </ListItem>
            ))}
          </ListItems>
        </List>
      )}
    </div>
  )
}
