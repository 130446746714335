import React from 'react'
import Icon from '../../components/icon'
import { ConfirmDialog } from '../../components/dialog'
import { request } from '../../utils/request'
import notify from '../../components/notify'

export default function PortfolioImage({
  entry,
  portfolio,
  dispatch,
  ...props
}) {
  const handleConfirm = async closeModal => {
    await request.delete(`/portfolio_entries/${entry.id}`)
    dispatch({
      portfolio: portfolio.filter(e => e.id !== entry.id),
    })
    notify.success('Your portfolio entry has been deleted')
    closeModal()
  }

  return (
    <div className="relative">
      <img src={entry.preview_url} {...props} />
      <ConfirmDialog
        triggerButtonContent={<Icon name="x" className="text-white" />}
        className="absolute p-1 -top-1 -right-1 w-6 h-6 bg-[#00000040] hover:bg-[#00000080] rounded-full flex items-center justify-center"
        onConfirm={handleConfirm}
        caption="Are you sure you want to delete this portfolio entry?"
      />
    </div>
  )
}
