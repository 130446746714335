import React from 'react'
import { Text, Heading } from '../../components/typography'

const SettingGroup = ({ children, title, description, required }) => {
  return (
    <div className="border-t border-gray-200 py-12 lg:flex gap-8">
      <div className="lg:w-2/6">
        <Heading level={4} size="lg" className="font-medium mb-2">
          {title}
          {required && (
            <span
              className="text-red-500 cursor-default"
              title="This is a required field"
            >
              *
            </span>
          )}
        </Heading>
        <Text className="text-gray-500 mb-4">{description}</Text>
      </div>
      <div className="flex-1 flex flex-col justify-center gap-4">
        {children}
      </div>
    </div>
  )
}

export default SettingGroup
