import React from 'react'
import Icon from '../../../components/icon'
import WidgetHeader from '../widget-header'

const RevenueItem = ({ title, value, percentage }) => {
  const isNetPositive = Number(percentage) > 0
  const percentageColor = isNetPositive ? 'text-green-500' : 'text-red-600'

  return (
    <div className="bg-gray-100 p-4 rounded-lg flex flex-col gap-2">
      <div className="text-sm text-gray-500">{title}</div>
      <div className="text-xl font-semibold flex gap-1 items-center">
        <span>${(value || 0).toFixed(2)}</span>
        {value > 0 && percentage !== 100 && (
          <span className={`${percentageColor} text-xs whitespace-nowrap`}>
            <Icon
              name={isNetPositive ? 'arrow-up' : 'arrow-down'}
              className="inline w-4 h-4 align-text-top"
            />
            {percentage}%
          </span>
        )}
      </div>
    </div>
  )
}

export default function StylistRevenue({ revenue }) {
  return (
    <div className="bg-white shadow rounded-lg p-4 flex flex-col gap-4">
      <WidgetHeader title="Revenue" />
      <div className="grid grid-cols-2 gap-4">
        <RevenueItem
          title="This week"
          value={revenue.week.income}
          percentage={revenue.week.difference}
        />
        <RevenueItem
          title="This month"
          value={revenue.month.income}
          percentage={revenue.month.difference}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <RevenueItem
          title="This year"
          value={revenue.year.income}
          percentage={revenue.year.difference}
        />
        <RevenueItem
          title="Total"
          value={revenue.total.income}
          percentage={revenue.total.difference}
        />
      </div>
    </div>
  )
}
