import { preRequisites } from '../payments'

describe('preRequisites', () => {
  it('returns false if all checks pass', () => {
    const list = [{ check: true }, { check: true }, { check: true }]

    expect(preRequisites(list)).toBe(false)
  })

  it('returns the error if any check fails', () => {
    const list = [
      { check: true },
      { check: false, error: 'Fatal failure' },
      { check: true },
    ]

    expect(preRequisites(list)).toBe('Fatal failure')
  })

  it('returns the first error if multiple checks fail', () => {
    const list = [
      { check: false, error: 'Fatal failure' },
      { check: false, error: 'Another failure' },
      { check: true },
    ]

    expect(preRequisites(list)).toBe('Fatal failure')
  })
})
