import React from 'react'
import cx from 'classnames'

export default function Hamburger({ children, className = '', ...props }) {
  const classes = cx(
    className,
    'rounded-lg w-12 h-12 overflow-hidden -indent-60 shadow-md relative after:absolute before:absolute after:inset-x-3 before:inset-x-3 after:inset-y-3 before:top-1/2 before:-mt-[1px] before:border-[currentColor] after:border-[currentColor] before:border-t-2 after:border-t-2 after:border-b-2'
  )
  return (
    <button className={classes} {...props}>
      {children}
    </button>
  )
}
