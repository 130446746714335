import React, { useState } from 'react'
import { Text } from '../components/typography'
import Button from '../components/button'
import {
  List,
  ListHeader,
  ListItem,
  ListItems,
  ListSection,
} from '../components/list'
import Empty from '../components/empty'
import { format } from '../utils/date'
import { request, handleRequestFailure } from '../utils/request'

export default function Appointments({ bookings: initialBookings }) {
  const [bookings, setBookings] = useState(initialBookings)
  const noBookings = bookings.length <= 0
  return (
    <div className="flex-1">
      {noBookings ? (
        <Empty title="You have not received any bookings yet">
          <Text>New bookings will show up here</Text>
        </Empty>
      ) : (
        <List view="table" columns="120px 100px 1fr 100px 100px">
          <ListItems>
            <ListHeader>
              <ListSection>Date</ListSection>
              <ListSection>Time</ListSection>
              <ListSection>Appointment</ListSection>
              <ListSection>Status</ListSection>
              <ListSection>Actions</ListSection>
            </ListHeader>
            {bookings.map(booking => {
              const date = new Date(booking.starts_at)
              const day = format(date, 'E, MMM do')
              const time = format(date, 'h:mm aaa')
              return (
                <ListItem key={booking.id}>
                  <ListSection>
                    <time>{day}</time>
                  </ListSection>
                  <ListSection>
                    <time dateTime={booking.starts_at}>{time}</time>
                  </ListSection>
                  <ListSection>
                    <Text>{booking.title || booking.generated_title}</Text>
                  </ListSection>
                  <ListSection>
                    <Text>{booking.status}</Text>
                  </ListSection>
                  <ListSection>
                    <BookingAction
                      booking={booking}
                      bookings={bookings}
                      setBookings={setBookings}
                    />
                  </ListSection>
                </ListItem>
              )
            })}
          </ListItems>
        </List>
      )}
    </div>
  )
}

function BookingAction({ booking, bookings, setBookings }) {
  const [loading, setLoading] = useState(false)
  const handleApprove = async () => {
    setLoading(true)
    try {
      const response = await request.put(`/bookings/${booking.id}`, {
        appointment: {
          status: 'accepted',
        },
      })
      setBookings(
        bookings.map(b => {
          if (b.id !== booking.id) return b

          return { ...booking, status: response.data.status }
        })
      )
    } catch (error) {
      handleRequestFailure(error)
    }
    setLoading(false)
  }

  const handleDecline = async () => {
    setLoading(true)
    try {
      const response = await request.put(`/bookings/${booking.id}`, {
        appointment: {
          status: 'declined',
        },
      })
      setBookings(
        bookings.map(b => {
          if (b.id !== booking.id) return b

          return { ...booking, status: response.data.status }
        })
      )
    } catch (error) {
      handleRequestFailure(error)
    }
    setLoading(false)
  }

  switch (booking.status) {
    case 'created': {
      return (
        <>
          <Button
            is="primary"
            onClick={loading ? null : handleApprove}
            disabled={loading}
          >
            Approve
          </Button>
          <Button
            is="secondary"
            onClick={loading ? null : handleDecline}
            disabled={loading}
          >
            Decline
          </Button>
          <Button is="secondary" href={`/bookings/${booking.id}`}>
            View more
          </Button>
        </>
      )
    }
    case 'accepted': {
      return (
        <Button is="secondary" href={`/bookings/${booking.id}`}>
          View more
        </Button>
      )
    }
    case 'completed': {
      return (
        <>
          <Button is="primary">Review</Button>
          <Button is="secondary" href={`/bookings/${booking.id}`}>
            View more
          </Button>
        </>
      )
    }
    case 'declined': {
      return (
        <Button is="secondary" href={`/bookings/${booking.id}`}>
          View more
        </Button>
      )
    }
    case 'cancelled': {
      return (
        <Button is="secondary" href={`/bookings/${booking.id}`}>
          View more
        </Button>
      )
    }
    default: {
      return null
    }
  }
}
