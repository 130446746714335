import React, { forwardRef } from 'react'
import cx from 'classnames'
import Icon from './icon'
import { hasTailwindPrefix } from '../utils/tailwind'

const sizes = {
  xs: 'py-1 px-2',
  sm: 'py-2 px-4',
  base: 'h-12 px-4',
  lg: 'py-4 px-10',
  xl: 'py-5 px-12',
  full: 'py-4 w-full',
  icon: 'w-12 h-12',
}

const variants = {
  primary: {
    size: 'base',
    classes:
      'bg-brown-300 border border-brown-300 hover:bg-brown-400 text-gray-700 rounded-lg',
  },
  secondary: {
    size: 'base',
    classes:
      'bg-white border border-gray-200 hover:bg-gray-100 text-gray-800 rounded-lg',
  },
  icon: {
    size: 'icon',
    classes:
      'bg-transparent hover:bg-gray-100 transition-colors duration-75 rounded-lg',
  },
}

const Button = forwardRef(function Button(
  {
    children,
    is,
    size,
    className,
    href,
    icon,
    iconBefore,
    iconAfter,
    disabled,
    type = 'submit',
    loading,
    onClick,
    ...props
  },
  ref
) {
  const isDisabled = disabled || loading
  const variant = icon ? 'icon' : is
  const btnSize = sizes[size || variants[variant]?.size]
  const btnClasses = variants[variant]?.classes || className
  const classes =
    cx(
      'inline-flex shrink-0 gap-2 items-center duration-100 ease-in-out transition-colors',
      {
        'bg-gray-100 hover:bg-gray-100 border-gray-100 text-gray-400': disabled,
        'cursor-not-allowed': isDisabled,
        'font-medium': !hasTailwindPrefix('font', className),
        'text-center justify-center': !hasTailwindPrefix(
          'text-(left|center|right)',
          className
        ),
      },
      btnSize,
      btnClasses
    ) || null
  const Component = href ? 'a' : 'button'

  const handleClick = event => {
    if (onClick) {
      if (href) {
        event.preventDefault()
        onClick(event)
        // When onClick is passed to a link, we navigate to the link after handling the click
        window.location.href = href
        return
      }
      onClick(event)
    }
  }

  return (
    <Component
      ref={ref}
      {...props}
      href={isDisabled ? null : href}
      disabled={isDisabled}
      type={href ? null : type}
      className={classes}
      onClick={handleClick}
    >
      {loading && (
        <svg
          className="animate-spin h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {icon ? (
        <Icon name={icon} className="w-5 h-5" />
      ) : (
        <>
          {iconBefore && <Icon name={iconBefore} className="w-5 h-5" />}
          {children}
          {iconAfter && <Icon name={iconAfter} className="w-5 h-5" />}
        </>
      )}
    </Component>
  )
})

export default Button
