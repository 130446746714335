import React, { Suspense } from 'react'
import { Loading } from '../components/loading'
import HeaderWidget from './components/widgets/header-widget'

export default function Dashboard({
  currentUser,
  appointments,
  notifications,
  clients,
  revenue,
}) {
  const Widgets = React.lazy(() =>
    import(`./components/${currentUser.role}-widgets`)
  )

  return (
    <div className="flex-1 flex flex-col gap-4">
      <HeaderWidget
        profileName={currentUser.profile_name}
        accountName={currentUser.account.name}
        avatar={currentUser.avatar}
        role={currentUser.role}
        notifications={notifications}
      />
      <Suspense fallback={<Loading />}>
        <Widgets
          appointments={appointments}
          clients={clients}
          revenue={revenue}
        />
      </Suspense>
    </div>
  )
}
