import React, { forwardRef } from 'react'
import cx from 'classnames'

export const Text = forwardRef(function Text(
  { as, size, className, ...props },
  ref
) {
  const Component = as || 'div'
  const classes =
    cx(
      {
        'text-sm': size === 'sm',
        'text-base': size === 'base',
        'text-lg': size === 'lg',
        'text-xl': size === 'xl',
        'text-2xl': size === '2xl',
        'text-3xl': size === '3xl',
      },
      className
    ) || null

  return <Component className={classes} ref={ref} {...props} />
})

export const Heading = forwardRef(function Heading(
  { level, size, className, ...props },
  ref
) {
  const Component = `h${level}`
  const classes =
    cx(
      'font-semibold',
      {
        'text-sm': size === 'sm',
        'text-base': size === 'base',
        'text-lg': size === 'lg',
        'text-xl': size === 'xl',
        'text-2xl': size === '2xl',
        'text-3xl': size === '3xl',
      },
      className
    ) || null

  return <Text as={Component} className={classes} ref={ref} {...props} />
})
