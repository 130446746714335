import React from 'react'
import Button from '../../../components/button'
import Icon from '../../../components/icon'
import WidgetHeader from '../widget-header'
import Avatar from '../../../components/avatar'
import StarRating from '../../../components/star-rating'

const Stylist = ({ name, rating, image }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-4 items-center">
        <Avatar src={image} alt={`customer ${name}`} size="xs" />
        <div className="flex flex-col">
          <div className="font-medium">{name}</div>
          <StarRating rating={rating} />
        </div>
      </div>
      <div>
        <Button
          title="Send a message"
          className="grid place-content-center bg-gray-100 hover:bg-gray-200 w-10 h-10 rounded-full"
        >
          <Icon name="mail" className="w-5 h-5 text-gray-800" />
        </Button>
      </div>
    </div>
  )
}

export default function ClientStylists() {
  return (
    <div className="bg-white shadow rounded-lg p-4 flex flex-col gap-5">
      <WidgetHeader title="Recent grooming services">
        <a href="/clients">View all</a>
      </WidgetHeader>

      <div className="flex flex-col gap-4">
        <Stylist
          name="Great Clips"
          image="https:ui-avatars.com/api/?name=Great+Clips"
          rating={3.5}
        />
        <Stylist
          name="Sport Clips"
          image="https:ui-avatars.com/api/?name=Sport+Clips"
          rating={4.9}
        />
        <Stylist
          name="Extreme Cuts"
          image="https:ui-avatars.com/api/?name=Extreme+Cuts"
          rating={4.7}
        />
      </div>
    </div>
  )
}
